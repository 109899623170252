import React from "react";
import Button from "./Button";

const MovieDetail = ({ button, active, data }) => {
  return (
    <div className="grid grid-cols-2 gap-5 md:gap-10 md:px-8">
      <div>
        <img
          src={`https://api.dakotacinema.id/${data.picturePath}`}
          alt={data.title}
          className="w-3/4 h-auto"
        />
      </div>
      <div>
        <h1 className="font-semibold text-2xl">{data.title}</h1>
        <p className="font-thin text-base">{data.genre}</p>
        <p className="font-thin text-base mb-2">{data.runTime}</p>
        <span className="rounded-full bg-[#D5D5D5] text-center py-1 px-2">{data.rating}</span>
        {button ? (
          <div className="mt-2 w-fit">
            <Button
              href="/pilih-teater"
              name="Pilih Teater"
              type="primary"
              active={active}
              data={data}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MovieDetail;
