import { Link } from "react-router-dom";

const MovieCard = ({ href, data }) => {
  return (
    <div className="bg-white px-2 pt-2 shadow-2xl">
      <Link to={href} state={data}>
        <div className="flex flex-col justify-between h-full">
          <img src={`https://api.dakotacinema.id/${data.picturePath}`} alt={data.title} className="h-full" />
          <div
            className={[
              "flex justify-center pt-2 pb-6 text-xs",
              data.title.length > 20
                ? "md:text-xs"
                : data.title.length > 15
                ? "md:text-sm"
                : "md:text-base",
            ].join(" ")}
          >
            {data.title}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default MovieCard;
