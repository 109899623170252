import formatThousand from "helpers/formatThousand";

const FnBItem = ({ data, onChange, handleMin, handlePlus }) => {
  return (
    <div>
      <div className="flex gap-4 px-8">
        {data.imagePath && (
          <img
            src={`https://api.dakotacinema.id/${data?.imagePath}`}
            alt={data.name}
            className="w-28 h-28"
          />
        )}
        <div className="flex flex-col gap-1 w-full">
          <div className="flex justify-between">
            <p className="font-medium text-base">{data.name}</p>
            <input
              type="checkbox"
              value={data.id}
              className="w-6 h-6 accent-green-600 border-0 focus:ring-0"
              onChange={onChange}
              checked={data.checked}
            />
          </div>
          <p className="font-light text-sm">{data.description}</p>
          <div className="flex justify-between gap-1">
            {/* <div className="flex gap-2 w-full items-center">
              <img
                src={require("../assets/images/Time.png")}
                alt="Time"
                className="object-contain"
              />
              <p className="font-light text-sm">Sekitar 10 menit</p>
            </div> */}
            <div className="flex items-center justify-between gap-1">
              <p className="font-light text-base" style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{data.purchasePriceNumber}</p>
            </div>
          </div>
          <div className="flex justify-between gap-1">
            {/* <div className="flex gap-2 w-full items-center">
              <img
                src={require("../assets/images/Time.png")}
                alt="Time"
                className="object-contain"
              />
              <p className="font-light text-sm">Sekitar 10 menit</p>
            </div> */}
            <div className="flex items-center justify-between gap-1">
              <p className="font-light text-base">Rp</p>
              <p className="font-light text-base">{formatThousand(data.purchasePriceNumber)}</p>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div
              className={[
                "flex items-center gap-2 border-slate-300 rounded",
                data.checked ? "visible" : "invisible",
              ].join(" ")}
            >
              <div
                className="bg-primary text-white px-1 rounded cursor-pointer"
                onClick={handleMin}
              >
                -
              </div>
              <p className="">{data.qty}</p>
              <div
                className="bg-primary text-white px-1 rounded cursor-pointer"
                onClick={handlePlus}
              >
                +
              </div>
            </div>
          </div>
          <div
            className={[
              "flex flex-col items-end",
              data.checked ? "visible" : "invisible",
            ].join(" ")}
          >
            <div className="grid justify-items-center">
              <p className="font-semibold text-sm"> {data.total}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-4">
        <div className="border-b-2"></div>
      </div>
    </div>
  );
};

export default FnBItem;
