import { useEffect, useState } from "react";
import Guest from "layout/Guest";
import MovieCard from "parts/MovieCard";
import Slider from "parts/Slider";
import movies from "constants/api/movies";

const Upcoming = () => {
  const [dataCS, setDataCS] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    movies
      .cs()
      .then((res) => {
        setDataCS(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Guest>
      <div className="pb-24">
        <Slider />
        <div className="flex flex-col space-y-4 md:justify-between justify-center m-auto left-0 right-0 max-w-7xl w-full px-8">
          <div className="flex justify-between items-center mt-10">
            <h3 className="text-sm md:text-base font-bold">Segera Tayang</h3>
          </div>
          <div className="grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-9">
            {dataCS.map((item, index) => {
              return (
                <MovieCard key={index} data={item} href={`/film-segera`} />
              );
            })}
          </div>
        </div>
      </div>
    </Guest>
  );
};

export default Upcoming;
