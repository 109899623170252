import Member from "layout/Member";
import Button from "parts/Button";
import React from "react";

const ChangePassword = () => {
  return (
    <Member>
      <div className="flex justify-center items-center bg-white h-full py-20">
        <div className="w-1/2 text-center">
          Tekan tombol di bawah untuk mengubah Kata Sandi. Sebuah email validasi
          akan dikirim kepada email Anda (y******ss@gmail.com)
          <div className="flex justify-center items-center mt-4 w-full">
            <Button name="Ubah Kata Sandi" type="primary" />
          </div>
        </div>
      </div>
    </Member>
  );
};

export default ChangePassword;
