import React from "react";
import ReactPlayer from "react-player";

const Trailer = ({ url, produser, director, penulis, pemain, distributor }) => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col w-full md:w-1/2 space-y-4 md:px-8">
        <div>
          <h3 className="font-medium mb-2">Tonton Trailer:</h3>
          <ReactPlayer url={url} width="100%" />
        </div>
        <div>
          <h3 className="font-medium">Produser:</h3>
          <p className="font-light text-base">{produser}</p>
        </div>
        <div>
          <h3 className="font-medium">Director:</h3>
          <p className="font-light text-base">{director}</p>
        </div>
        <div>
          <h3 className="font-medium">Penulis:</h3>
          <p className="font-light text-base">{penulis}</p>
        </div>
        <div>
          <h3 className="font-medium">Pemain:</h3>
          <p className="font-light text-base">{pemain}</p>
        </div>
        <div>
          <h3 className="font-medium">Distributor:</h3>
          <p className="font-light text-base">{distributor}</p>
        </div>
      </div>
    </div>
  );
};

export default Trailer;
