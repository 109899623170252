import React, { useEffect, useState } from "react";
import Guest from "layout/Guest";
import useForm from "helpers/hooks/useForm";
import Input from "components/Form/Input";
import Button from "parts/Button";
import users from "constants/api/users";
import { useNavigate } from "react-router-dom";

const Login = () => {
  let navigate = useNavigate();
  const [error, setError] = useState([]);
  const [{ username, password }, setState] = useForm({
    username: "",
    password: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      navigate("/");
    }
  }, []);

  const submit = (e) => {
    e.preventDefault();
    const err = [];
    if (username === "") {
      if (!err.includes("Username belum diisi")) {
        err.push("Username belum diisi");
      }
    }
    if (password === "") {
      if (!err.includes("Password belum diisi")) {
        err.push("Password belum diisi");
      }
    }
    if (err.length > 0) {
      setError(err);
      return false;
    }

    users
      .login({ username, password })
      .then((res) => {
        const user = res.data.result.user;
        const token = res.data.result.accessToken;
        const refreshToken = res.data.result.refreshToken;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("access_token", token);
        localStorage.setItem("refresh_token", refreshToken);
        navigate(-1);
      })
      .catch(() => {
        err.push("Pastikan username dan password anda sudah benar");
        setError(err);
        return false;
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Guest>
      <div className="flex h-screen justify-center p-4 md:p-14 bg-white ">
        <div className="flex flex-col space-y-8 w-full sm:w-1/2">
          <div className="flex justify-center">
            <img className="h-12" src={require("../assets/images/logo-default.png")} alt="dakota" />
          </div>
          {error.length !== 0 && (
            <ul className="flex flex-col text-sm w-full text-primary bg-orange-100 rounded-md px-8">
              {error.map((item, index) => {
                return (
                  <li key={index} className="list-disc">
                    {item}
                  </li>
                );
              })}
            </ul>
          )}
          <form onSubmit={submit}>
            <Input
              value={username}
              name="username"
              type="email"
              labelName="Username"
              placeholder="Tulis username"
              onChange={setState}
            ></Input>
            <Input
              value={password}
              name="password"
              type="password"
              labelName="Kata Sandi"
              placeholder="Tulis Kata Sandi"
              onChange={setState}
            ></Input>
            <div className="flex flex-col space-y-4 ">
              <Button name="Masuk" type="primary" onClick={submit} />
              <p className="text-center">atau</p>
              <Button href="/register" name="Register" type="light" />
            </div>
          </form>
        </div>
      </div>
    </Guest>
  );
};

export default Login;
