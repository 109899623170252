import React, { useEffect } from "react";
import Guest from "layout/Guest";
import MovieDetail from "parts/MovieDetail";
import Trailer from "parts/Trailer";
import { useLocation } from "react-router-dom";
import NotFound from "./NotFound";

const Movie = () => {
  const location = useLocation();
  const data = location.state;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Guest>
      {data ? (
        <div className="pb-24 pt-4 bg-white">
          <div className="flex flex-col space-y-4 md:justify-between justify-center m-auto left-0 right-0 max-w-7xl w-full px-8">
            <div className="flex flex-col space-y-4">
              <div className="flex justify-center">
                <div className="flex flex-col w-full md:w-1/2 space-y-4">
                  <MovieDetail data={data} button={true} active={true} />
                  <p className="font-light text-base md:px-8">
                    {data.description}
                  </p>
                </div>
              </div>
              <Trailer
                url={`https://www.youtube.com/watch?v=${data?.trailer}`}
                produser={data?.producer}
                director={data?.director}
                penulis={data?.writer}
                pemain={data?.casts}
                distributor={data?.distributor}
              />
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </Guest>
  );
};

export default Movie;
