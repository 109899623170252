import Guest from "layout/Guest";
import React, { useEffect } from "react";

const Promotion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Guest>
      <div className="pb-20 bg-white">
        <div className="flex flex-col space-y-4">
          <img alt="imag" src={require("../assets/images/b1.jpeg")} />
          <img alt="imag" src={require("../assets/images/b2.jpeg")} />
          <img alt="imag" src={require("../assets/images/b3.jpeg")} />
          <img alt="imag" src={require("../assets/images/b4.jpeg")} />
        </div>
      </div>
    </Guest>
  );
};

export default Promotion;
