import orders from "constants/api/orders";
import theaters from "constants/api/theaters";
import Member from "layout/Member";
import TransactionItem from "parts/TransactionItem";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Transaction = () => {
  const [title, setTitle] = React.useState("Tiket");
  const [data, setData] = React.useState([]);
  const [ticket, setTicket] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [dataMenu, setDataMenu] = React.useState([]);
  const location = useLocation();
  const menu = location.state;

  const handleMenu = (title, type) => {
    setTitle(title);
    // console.log(type);
    // const res = [
    //   {
    //     id: 1,
    //     image: "https://picsum.photos/1600",
    //     name: "Tiket 1",
    //     theater: "Cilacap",
    //     time: "12:00",
    //     seat: "A1",
    //     price: 10000,
    //     type: "tiket",
    //     status: "PENDING",
    //   },
    //   {
    //     id: 2,
    //     image: "https://picsum.photos/1600",
    //     name: "Tiket 1",
    //     theater: "Cilacap",
    //     time: "12:00",
    //     seat: "A1",
    //     price: 20000,
    //     type: "tiket",
    //     status: "SELESAI",
    //   },
    //   {
    //     id: 3,
    //     image: "https://picsum.photos/1600",
    //     name: "Kafetaria 1",
    //     theater: "Cilacap",
    //     price: 30000,
    //     type: "fnb",
    //     status: "PENDING",
    //   },
    //   {
    //     id: 4,
    //     image: "https://picsum.photos/1600",
    //     name: "Tiket 1",
    //     theater: "Cilacap",
    //     time: "12:00",
    //     seat: "B1,B2",
    //     price: 10000,
    //     type: "tiket",
    //     status: "LUNAS",
    //   },
    //   {
    //     id: 5,
    //     image: "https://picsum.photos/1600",
    //     name: "Tiket 1",
    //     theater: "Cilacap",
    //     time: "12:00",
    //     seat: "A1",
    //     price: 20000,
    //     type: "tiket",
    //     status: "BATAL",
    //   },
    //   {
    //     id: 6,
    //     image: "https://picsum.photos/1600",
    //     name: "Kafetaria 1",
    //     theater: "Cilacap",
    //     price: 30000,
    //     type: "fnb",
    //     status: "LUNAS",
    //   },
    // ];
    // let f = data.filter((item) => item.type === type && item.status === "LUNAS");
    // if (menu === "transaction") {
    //   f = res.filter((item) => item.type === type && item.status !== "LUNAS");
    // }

    // setDataMenu(data);
  };

  // async function fetchData() {
  //   try {
  //     const res = await theaters.all();
  //     const dataTheater = res.data.result.data;
  //     const subTicket = [];
  //     dataTheater.map(async (theater) => {
  //       console.log(theater.id);
  //       orders
  //       .history({ branch: theater.id })
  //       .then((res) => {
  //         const arrDataTransactionTicket = [];
  //         const arrTicket = [];
  //         const arrProduct = [];

  //         if (res.data.result.data != null && res.data.result.data != "") {
  //           arrDataTransactionTicket.push(res.data.result.data);
  //         }
  //         arrDataTransactionTicket.map((item) => {
  //           item.map((item2) => {
  //             if (item2.category === "ticket") {
  //               const seat = [];
  //               item2.items.map((movie) => {
  //                 seat.push(movie.seat.name);
  //               });
  //               var dataTicket = [
  //                 {
  //                   name: item2.items[0].screening.movie.title,
  //                   image: item2.items[0].screening.movie.picturePath,
  //                   code: item2.code,
  //                   status: item2.status,
  //                   time: item2.items[0].screening.time,
  //                   seat: seat.join(", "),
  //                   price: item2.totalAmount,
  //                   theater: theater.name,
  //                 },
  //               ];
  //               // console.log('tiket',dataTicket);
  //               subTicket.push(...dataTicket);
  //               // setTicket(arrTicket);
  //             }

  //             if (item2.category === "product") {
  //               item2.items.map((product) => {
  //                 // console.log(product);
  //                 var dataProduct = [
  //                   {
  //                     name: product.product.name,
  //                     // image: product.screening.movie.picturePath,
  //                     code: product.product.code,
  //                     status: item2.status,
  //                     price: product.product.listPrice,
  //                   },
  //                 ];
  //                 // console.log(dataProduct);
  //                 arrProduct.push(...dataProduct);

  //                 setProduct(arrProduct);
  //               });
  //             }
  //           });
  //         });
  //         // setData(res.data.result.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //       // https://api.sandbox.midtrans.com/v2/qris/3ded33e2-deaf-4114-9356-a7e012a779c1/qr-code
  //     })
  //     console.log('sub',subTicket);
  //     setTicket(subTicket);
  //   } catch (error) {
  //     console.log("Error while fetching theater:", error);
  //   }
  // }

  async function fetchData() {
    try {
      const res = await theaters.all();
      const dataTheater = res.data.result.data;
      const subTicket = [];
      const subProduct = [];
  
      await Promise.all(
        dataTheater.map(async (theater) => {
          // console.log(theater.id);
          try {
            const response = await orders.history({ branch: theater.id });
            const arrDataTransactionTicket = [];
  
            if (response.data.result.data != null && response.data.result.data != "") {
              arrDataTransactionTicket.push(response.data.result.data);
            }
  
            arrDataTransactionTicket.forEach((item) => {
              item.forEach((item2) => {
                if (item2.category === "ticket" && item2.status == 'pending') {
                  const seat = item2.items.map((movie) => movie.seat.name);
                  var dataTicket = {
                    name: item2.items[0].screening.movie.title,
                    image: item2.items[0].screening.movie.picturePath,
                    code: item2.code,
                    status: item2.status,
                    time: item2.items[0].screening.time,
                    seat: seat.join(", "),
                    price: item2.totalAmount,
                    theater: theater.name,
                    screeningDate: item2.items[0].screening.date,
                    theaterId: theater.id,
                    payment: item2
                  };
                  subTicket.push(dataTicket);
                }
  
                if (item2.category === "product" && item2.status == 'pending') {
                  item2.items.forEach((product) => {
                    var dataProduct = {
                      name: product.product.name,
                      image: product.product.imagePath,
                      code: product.product.code,
                      status: item2.status,
                      price: product.product.purchasePrice,
                      theater: theater.name,
                      theaterId: theater.id,
                      payment: item2
                    };
                    subProduct.push(dataProduct);
                  });
                }
              });
            });
          } catch (error) {
            console.log(`Error while fetching orders for theater ${theater.id}:`, error);
          }
        })
      );
      setTicket(subTicket);
      setProduct(subProduct);
    } catch (error) {
      console.log("Error while fetching theater:", error);
    }
  }
  

  useEffect(() => {
    fetchData();
    
  }, []);

  useEffect(() => {
    handleMenu("Tiket", "tiket");
  }, [menu]);

  return (
    <Member>
      <div className="flex flex-col md:flex-row bg-white h-full pb-20">
        <ul className="grid grid-flow-col md:grid-flow-row text-center my-4 min-w-[225px] h-fit bg-[#EFEFEF] mx-4">
          <li
            className={
              "p-2 hover:bg-secondary " +
              (title === "Tiket" ? "bg-secondary" : "")
            }
          >
            <button onClick={() => handleMenu("Tiket", "tiket")}>Tiket</button>
          </li>
          <li
            className={
              "p-2 hover:bg-secondary " +
              (title === "Kafetaria" ? "bg-secondary" : "")
            }
          >
            <button onClick={() => handleMenu("Kafetaria", "fnb")}>
              Kafetaria
            </button>
          </li>
        </ul>
        {/* <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-xl font-bold mb-4">Transaction History</h2>
            <table className="min-w-full">
              <thead>
                <tr className="border-b border-gray-200">
                  <th className="py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider">
                    Code
                  </th>
                  <th className="py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider">
                    Category
                  </th>
                  <th className="py-3 text-right text-xs font-bold text-gray-600 uppercase tracking-wider">
                    Total Amount
                  </th>
                  <th className="py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((transaction) => (
                  <tr key={transaction.id} className="border-b border-gray-200">
                    <td className="py-4 text-sm font-medium text-gray-900">
                      {transaction.date}
                    </td>
                    <td className="py-4 text-sm font-medium text-gray-900">
                      {transaction.code}
                    </td>
                    <td className="py-4 text-sm font-medium text-gray-900">
                      {transaction.category}
                    </td>
                    <td className="py-4 text-sm font-medium text-right text-gray-900">
                      {transaction.totalAmount}
                    </td>
                    <td className="py-4 text-sm font-medium text-left text-gray-900">
                      {transaction.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
        {title === "Tiket" && (
          <div className="flex flex-col gap-4 my-4 w-full">
            {ticket.map((item, index) => {
              return (
                <TransactionItem
                  key={index}
                  name={item.name}
                  image={item.image}
                  theater={item.theater}
                  time={item.time}
                  seat={item.seat}
                  price={item.price}
                  code={item.code}
                  status={item.status}
                  screeningDate={item.screeningDate}
                  theaterId={item.theaterId}
                  payment={item.payment}
                />
              );
            })}
          </div>
        )}
        {title === "Kafetaria" && (
          <div className="flex flex-col gap-4 my-4 w-full">
            {product.map((item, index) => {
              return (
                <TransactionItem
                  key={index}
                  name={item.name}
                  image={item.image}
                  theater={item.theater}
                  price={item.price}
                  type="fnb"
                  code={item.code}
                  status={item.status}
                  theaterId={item.theaterId}
                  payment={item.payment}
                />
              );
            })}
          </div>
        )}
      </div>
    </Member>
  );
};

export default Transaction;
