import axios from "configs/axios/v1/index";
export default {
  get: (data) =>
    axios.get(`/ticket?screeningId=${data.screeningId}`, {
      headers: {
        "dakota-branch-id": data.branch,
      },
    }),
  select: (data) =>
    axios.post(`/ticket`, data.param, {
      headers: { "dakota-branch-id": data.branch },
    }),
  unselect: (data) =>
    axios.delete(`/ticket`, {
      headers: { "dakota-branch-id": data.branch },
      data: data.param,
    }),
  // all: (options = { params: {} }) => axios.post(`/ScreenSeat`, options),
  // preOrder: (options = { params: {} }) =>
  //   axios.post(`/GetPreorderTicket`, options),
  // savePreOrder: (options = { params: {} }) =>
  //   axios.post(`/SavePreorderTicket`, options),
  // deletePreOrder: (options = { params: {} }) =>
  //   axios.delete(`/DeletePreorderTicket`, { data: options }),
  // updaatePreOrder: (options = { params: {} }) =>
  //   axios.put(`/UpdatePreorderTicket`, options),
  // columnSeat: (options = { params: {} }) =>
  //   axios.post(`/GetColumnSeat`, options),
};
