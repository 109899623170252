import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="md:flex justify-center w-full bg-[#878787] px-44 py-16 border-b-[30px] border-[#000000] hidden">
      <div className="flex justify-between">
        <div className="grid grid-cols-4 mr-12">
          <h3 className="font-semibold text-xl mb-5 col-span-4">Jelajahi</h3>
          <div className="font-normal text-base">
            <p className="mb-5"><Link to="/home">Tayang</Link></p>
            <p className="mb-5"><Link to="/segera">Segera</Link></p>
            <p className="mb-5"><Link to="/teater">Teater</Link></p>
          </div>
          <div className="font-normal text-base">
            <p className="mb-5"><Link to="/kafetaria">Kafetaria</Link></p>
            {/* <p className="mb-5"><Link to="/promosi">Promosi</Link></p> */}
            {/* <p className="mb-5"><Link to="/investment">Investment</Link></p> */}
          </div>
          <div className="font-normal text-base">
            <p className="mb-5"><Link to="/transaksi">Transaksi Aktif</Link></p>
            {/* <p className="mb-5"><Link to="/transaksi">Riwayat Transaksi</Link></p> */}
            {/* <p className="mb-5"><Link to="/home">Teater Favorit</Link></p> */}
          </div>
          <div className="font-normal text-base">
            <p className="mb-5"><Link to="/persyaratan-layanan">Syarat Penggunaan / Penafian</Link></p>
            {/* <p className="mb-5"><Link to="/home">Kebijakan Pribadi</Link></p> */}
            {/* <p className="mb-5"><Link to="/home">FAQ</Link></p> */}
          </div>
        </div>
        <div>
          <h3 className="font-semibold text-xl mb-1">Hubungi Kami</h3>
          <div className="flex items-center">
            <div className="mr-3">
              <img src={require("../assets/images/Whatsapp.png")} alt="" />
            </div>
            <div className="mr-3">
              <img src={require("../assets/images/Email.png")} alt="" />
            </div>
          </div>
          <h3 className="font-semibold text-xl mb-1 pt-5">Ikuti Kami</h3>
          <div className="flex items-center">
            <div className="mr-3">
              <img src={require("../assets/images/Instagram.png")} alt="" />
            </div>
            <div className="mr-3">
              <img src={require("../assets/images/Tiktok.png")} alt="" />
            </div>
            <div className="mr-3">
              <img src={require("../assets/images/Facebook.png")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
