import formatThousand from "helpers/formatThousand";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const TransactionItem = ({
  image,
  name,
  code,
  theater,
  time,
  seat,
  expired,
  price,
  type,
  status,
  screeningDate,
  theaterId,
  payment
}) => {
  return (
    <Link
      to={status != 'settled' ? "/riwayat-pembayaran" : "/rincian-transaksi"}
      state= {{
        data: payment,
        theaterId: theaterId
      }}
      className="flex items-center gap-2 font-light hover:text-primary"
    >
      <div className="flex justify-between w-full gap-4 px-8">
        {image && (
          <div className="lg:w-[12%] md:w-1/4 w-1/3">
            <img src={`https://api.dakotacinema.id/${image}`} alt={name} />
          </div>
        )}
        <div className="flex flex-col justify-between w-full">
          <div className="flex justify-between">
            <p className="font-medium text-base">{name}</p>
            <p className="font-medium">{price}</p>
          </div>
          <p className="font-light text-sm">Kode Order {code}</p>
          <div className="flex justify-between">
            <p className="font-light text-sm">{theater}</p>
            {status === "settled" ? (
              <span className={"rounded-md px-2 bg-secondary"}>LUNAS</span>
            ) : status === "expired" ? (
              <span className={"rounded-md px-2 bg-primary"}>BATAL</span>
            ) : (
              <span className={"rounded-md px-2 bg-blue-300"}>MENUNGGU</span>
            )}
          </div>
          <div>
            <p> Tgl Film : {screeningDate}</p>
          </div>
          <div className="flex justify-between gap-4">
            {type !== "fnb" ? (
              <div className="flex gap-4">
                <div className="flex gap-1 font-light text-sm bg-[#D9D9D9] p-2 w-fit items-center">
                  <img
                    src={require("../assets/images/Clock.png")}
                    alt="Clock"
                    className="w-6 h-6"
                  />
                  {moment(time, "HH:mm:ss").format("HH:mm")}
                </div>
                <div className="flex gap-1 font-light text-sm bg-[#D9D9D9] p-2 w-fit items-center">
                  <img
                    src={require("../assets/images/Armchair.png")}
                    alt="Armchair"
                    className="w-6 h-6"
                  />
                  {seat}
                </div>
              </div>
            ) : (
              <div className="mb-10"></div>
            )}
            <div className="flex flex-col items-end">
              {type !== "fnb" ? (
                <div className="flex font-light text-sm gap-1">
                  {/* <img
                    src={require("../assets/images/Time.png")}
                    alt="Time"
                    className="w-6 h-6"
                  />
                  Sisa waktu {expired} */}
                </div>
              ) : (
                <div></div>
              )}

              {/* <p className="font-medium">{`Rp${formatThousand(price)},-`}</p>
              <span>Lunas</span> */}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TransactionItem;
