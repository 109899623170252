import Guest from "layout/Guest";
import MovieDetail from "parts/MovieDetail";
import MovieTime from "parts/MovieTime";
import TheaterDetail from "parts/TheaterDetail";
import React, { useEffect, useState } from "react";
import screening from "constants/api/screening";
import { useLocation } from "react-router-dom";
import NotFound from "./NotFound";
import moment from "moment";
const TheaterMovies = () => {
  const location = useLocation();
  const theater = location.state;
  const idTheater = theater?.id;
  const [dataMovies, setDataMovies] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  useEffect(() => {
    // Update the currentDateTime state every second
    const interval = setInterval(() => {
      setCurrentDateTime(moment());
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, []);

  const toggleTab = (index) => {
    if (activeTab === index) {
      setActiveTab(null);
    } else {
      setActiveTab(index);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(currentDateTime.format('YYYY-MM-DD'));
    // console.log(currentDateTime.format('HH:mm:ss'));
    // console.log(currentDateTime.clone().add(3, 'days').format('YYYY-MM-DD'))
    if (theater) {
      screening
        .movie({ type: "now_playing", branch: idTheater })
        .then((res) => {
          const dataMovies = res.data.result.data.map((movie) => {
            const timePromise = screening
              .get({ movie: movie.id, branch: theater.id, startDate: currentDateTime.format('YYYY-MM-DD'), endDate: currentDateTime.clone().add(2, 'days').format('YYYY-MM-DD')})
              .then((res) => {
                const data = res.data.result.data;
                const showTime = data
                  .filter(
                    (ele, ind) =>
                      ind === data.findIndex((elem) => elem.date === ele.date)
                  )
                  .map((item) => {
                    const time = data
                      .filter((item2) => {
                        return item2.date === item.date;
                      })
                      .map((datatimescreening) => {
                        return datatimescreening;
                      });
                    return { ...item, times: time, theater: theater };
                  });
                return showTime;
              })
              .catch((err) => {
                console.log(err);
              });
            return Promise.all([timePromise]).then(([time]) => {
              return { ...movie, theater: theater, screenings: time };
            });
          });
          Promise.all(dataMovies).then((dataMovies) => {
            setDataMovies(dataMovies);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <Guest>
      {theater ? (
        <div className="py-20 bg-white">
          <div className="flex flex-col space-y-4">
            <div className="flex justify-center">
              <div className="flex flex-col w-full md:w-1/2 space-y-10">
                <div className="lex flex-col space-y-4 bg-[#F1F1F1] py-4">
                  <img src={theater.image} alt="" className="w-full px-8" />
                  <TheaterDetail data={theater} />
                </div>
                {dataMovies.map((movie, i) => (
                  <div className="flex flex-col space-y-10" key={i}>
                    <MovieDetail data={movie} button={false} />
                    <p
                      className="flex items-center justify-between underline cursor-pointer transition-transform duration-300"
                      onClick={() => toggleTab(i)}
                    >
                      LIHAT JAM TAYANG
                      <svg
                        className={`w-4 h-4 ml-2 ${
                          activeTab === i ? "transform rotate-180" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M19 9l-7 7-7-7" />
                      </svg>
                    </p>
                    <div
                      className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
                        activeTab === i ? "max-h-96" : "max-h-0"
                      }`}
                    >
                      {movie.screenings.length > 0 ? (
                        <div className="flex flex-col space-y-8 px-8">
                          {movie.screenings.map((show, j) => (
                            <MovieTime
                              key={j}
                              date={show.date}
                              price={show.currentPricing}
                              times={show.times}
                              data={movie}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="p-8 text-gray-800 bg-slate-300 rounded-lg">
                          Jam tayang belum tersedia.
                        </p>
                      )}
                    </div>
                    {i < dataMovies.length - 1 && (
                      <div className="w-full">
                        <div className="border-2"></div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </Guest>
  );
};

export default TheaterMovies;
