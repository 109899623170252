import axios from "configs/axios/v1/index";
export default {
  save: (data) =>
    axios.post(`/cart`, data.param, {
      headers: { "dakota-branch-id": data.branch },
    }),
  get: (data) =>
    axios.get(`/cart?type=${data.type}`, {
      headers: {
        "dakota-branch-id": data.branch,
      },
    }),
};
