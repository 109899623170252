import React from "react";

const CartItem = ({
  image,
  name,
  kode,
  theater,
  time,
  seat,
  expired,
  price,
  type,
}) => {
  return (
    <div className="flex justify-between w-full gap-4 px-8">
      {image && (
        <div className="w-1/6">
          <img src={`https://api.dakotacinema.id/${image}`} alt={name} className="w-full h-full" />
        </div>
      )}
      <div className="flex flex-col justify-between w-full">
        <div className="flex justify-between">
          <p className="font-medium text-base">{name}</p>
          {/* <input
            type="checkbox"
            className="w-6 h-6 accent-green-600 border-0 focus:ring-0"
          /> */}
        </div>
        {/* <p className="font-light text-sm">Kode Order {kode}</p> */}
        <p className="font-light text-sm">{theater}</p>
        <div className="flex justify-between gap-4">
          {type !== "fnb" ? (
            <div className="flex gap-4">
              <div className="flex gap-1 font-light text-sm bg-[#D9D9D9] p-2 w-fit items-center">
                <img
                  src={require("../assets/images/Clock.png")}
                  alt="Clock"
                  className="w-6 h-6"
                />
                {time}
              </div>
              <div className="flex gap-1 font-light text-sm bg-[#D9D9D9] p-2 w-fit items-center">
                <img
                  src={require("../assets/images/Armchair.png")}
                  alt="Armchair"
                  className="w-6 h-6"
                />
                {seat}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="flex flex-col items-end">
            {type !== "fnb" ? (
              <div className="flex font-light text-sm gap-1">
                {/* <img
                  src={require("../assets/images/Time.png")}
                  alt="Time"
                  className="w-6 h-6"
                />
                Sisa waktu {expired} */}
              </div>
            ) : (
              <div></div>
            )}

            <div className="font-light text-sm">{price}</div>
          </div>
        </div>
        <div className="w-full py-4">
          <div className="border-b-2"></div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
