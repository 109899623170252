import Input from "components/Form/Input";
import users from "constants/api/users";
import useForm from "helpers/hooks/useForm";
import Guest from "layout/Guest";
import moment from "moment/moment";
import Button from "parts/Button";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Register = () => {
  let navigate = useNavigate();
  const [
    {
      username,
      firstName,
      lastName,
      phone,
      email,
      address,
      province,
      city,
      gender,
      date,
      month,
      year,
      password,
      confirmPassword,
      avatar,
    },
    setState,
  ] = useForm({
    username: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    province: "",
    city: "",
    gender: "Laki-laki",
    date: "1",
    month: "1",
    year: "1970",
    password: "",
    confirmPassword: "",
    avatar: "",
  });
  const [error, setError] = useState([]);
  const [agreement, setAgreement] = useState(false);
  const numberToMonth = {
    '1':'Januari',
    '2':'Februari',
    '3':'Maret',
    '4':'April',
    '5':'Mei',
    '6':'Juni',
    '7':'Juli',
    '8':'Agustus',
    '9':'September',
    '10':'Oktober',
    '11':'November',
    '12':'Desember',
  };
  
  const submit = (e) => {
    e.preventDefault();
    const err = [];
    if (firstName === "") {
      if (!err.includes("Nama lengkap belum diisi")) {
        err.push("Nama lengkap belum diisi");
      }
    }

    if (phone === "") {
      if (!err.includes("No HP belum diisi")) {
        err.push("No HP belum diisi");
      }
    }

    if (email === "") {
      if (!err.includes("Email belum diisi")) {
        err.push("Email belum diisi");
      }
    }

    if (address === "") {
      if (!err.includes("Alamat belum diisi")) {
        err.push("Alamat belum diisi");
      }
    }

    if (province === "") {
      if (!err.includes("Provinsi belum diisi")) {
        err.push("Provinsi belum diisi");
      }
    }

    if (city === "") {
      if (!err.includes("Kota/Kabupaten belum diisi")) {
        err.push("Kota/Kabupaten belum diisi");
      }
    }

    if (gender === "") {
      if (!err.includes("Jenis Kelamin belum diisi")) {
        err.push("Jenis Kelamin belum diisi");
      }
    }

    if (month === "" || date === "" || year === "") {
      if (!err.includes("Tanggal Lahir belum diisi")) {
        err.push("Tanggal Lahir belum diisi");
      }
    }

    if (username === "") {
      if (!err.includes("Username belum diisi")) {
        err.push("Username belum diisi");
      }
    }

    if (password === "") {
      if (!err.includes("Kata Sandi belum diisi")) {
        err.push("Kata Sandi belum diisi");
      }
    }

    if (password !== confirmPassword) {
      if (!err.includes("Kata sandi tidak sesuai")) {
        err.push("Kata sandi tidak sesuai");
      }
    }

    if (err.length > 0) {
      setError(err);
      return false;
    }

    users
      .register({
        username,
        email,
        firstName,
        lastName,
        phone,
        password,
        confirmPassword,
        address,
        province,
        city,
        gender,
        dob: `${year}-${month}-${date}`,
        avatar,
      })
      .then((res) => {

        Swal.fire({
          text: "Berhasil membuat akun.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(
      username,
      firstName,
      lastName,
      phone,
      email,
      address,
      province,
      city,
      gender,
      date,
      month,
      year,
      password
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error]);
  return (
    <Guest>
      <div className="pb-24 bg-white">
        <div className="flex justify-center">
          <div className="flex flex-col w-full px-4 md:w-1/2 space-y-2 items-center">
            <h3 className="font-semibold text-lg">
              Isi data di bawah untuk registrasi
            </h3>
            {error.length !== 0 && (
              <ul className="flex flex-col text-sm w-full text-primary bg-orange-100 rounded-md px-8">
                {error.map((item, index) => {
                  return (
                    <li key={index} className="list-disc">
                      {item}
                    </li>
                  );
                })}
              </ul>
            )}
            <div className="flex flex-col mb-4 w-full">
              <div className="flex space-x-4">
                <Input
                  value={firstName}
                  name="firstName"
                  type="text"
                  labelName="Nama lengkap sesuai KTP*"
                  placeholder=""
                  onChange={setState}
                />
                {/* <Input
                  value={lastName}
                  name="lastName"
                  type="text"
                  labelName="Nama belakang"
                  placeholder=""
                  onChange={setState}
                /> */}
              </div>
            </div>
            <Input
              value={phone}
              name="phone"
              type="text"
              labelName="Nomor HP*"
              placeholder=""
              onChange={setState}
            />
            <Input
              value={email}
              name="email"
              type="email"
              labelName="Email*"
              placeholder=""
              onChange={setState}
            />
            <Input
              value={address}
              name="address"
              type="text"
              labelName="Alamat sesuai KTP"
              placeholder=""
              onChange={setState}
            />
            <Input
              value={province}
              name="province"
              type="text"
              labelName="Provinsi"
              placeholder=""
              onChange={setState}
            />
            <Input
              value={city}
              name="city"
              type="text"
              labelName="Kota/Kabupaten"
              placeholder=""
              onChange={setState}
            />
            {/* <Input
              value={gender}
              name="gender"
              type="text"
              labelName="Jenis Kelamin"
              placeholder=""
              onChange={setState}
            /> */}
            <div className="flex flex-col mb-4 w-full">
              <label
                htmlFor="Tanggal Lahir"
                className={["text-base font-light mb-2"].join(" ")}
              >
                {" "}
                Jenis Kelamin
              </label>
              <select
                value={gender}
                name="gender"
                className="bg-[#EFEFEF] appearance-none rounded-lg focus:outline-none border-none w-full px-6 py-3"
                onChange={setState}
              >
                <option key={0} value="Laki-laki">
                  Laki-laki
                </option>
                <option key={1} value="Perempuan">
                  Perempuan
                </option>
              </select>
            </div>
            <div className="flex flex-col mb-4 w-full">
              <label
                htmlFor="Tanggal Lahir"
                className={["text-base font-light mb-2"].join(" ")}
              >
                {" "}
                Tanggal Lahir
              </label>
              <div className="flex space-x-4">
                <select
                  value={date}
                  name="date"
                  className="bg-[#EFEFEF] appearance-none rounded-lg focus:outline-none border-none w-full px-6 py-3"
                  onChange={setState}
                >
                  {[...Array(31)].map((_, i) => {
                    const displayValue = i + 1;
                    const value =
                      displayValue <= 9 ? `0${displayValue}` : displayValue;

                    return (
                      <option key={value} value={value}>
                        {displayValue}
                      </option>
                    );
                  })}
                </select>
                <select
                  value={month}
                  name="month"
                  className="bg-[#EFEFEF] appearance-none rounded-lg focus:outline-none border-none w-full px-6 py-3"
                  onChange={setState}
                >
                  {[...Array(12)].map((_, i) => {
                    const displayValue = i + 1;
                    const value =
                      displayValue <= 9 ? `0${displayValue}` : displayValue;

                    return (
                      <option key={value} value={value}>
                        {numberToMonth[displayValue]}
                      </option>
                    );
                  })}
                </select>
                <select
                  value={year}
                  name="year"
                  className="bg-[#EFEFEF] appearance-none rounded-lg focus:outline-none border-none w-full px-6 py-3"
                  onChange={setState}
                >
                  {}
                  {[...Array(moment().format("YYYY") - 1970)].map((_, i) => {
                    return (
                      <option key={i + 1970} value={i + 1970}>
                        {i + 1970}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <Input
              value={username}
              name="username"
              type="text"
              labelName="Username"
              placeholder=""
              onChange={setState}
            />
            <Input
              value={password}
              name="password"
              type="password"
              labelName="Kata Sandi"
              placeholder=""
              onChange={setState}
            />
            <Input
              value={confirmPassword}
              name="confirmPassword"
              type="password"
              labelName="Tulis Ulang Kata Sandi"
              placeholder=""
              onChange={setState}
            />
            <div>
              <div className="flex w-full gap-4 ">
                <input
                  type="checkbox"
                  className="w-14 h-14 accent-green-600 border-0 focus:ring-0"
                  onClick={() => setAgreement(!agreement)}
                />
                <p className="font-light">
                  Dengan melakukan registrasi, saya telah setuju dengan{" "}
                  <a href="#" className="text-primary underline">
                    Kebijakan Pribadi, Syarat & Ketentuan
                  </a>
                  ,{" "}
                  <a href="#" className="text-primary underline">
                    dan Syarat Penggunaan / Penafian
                  </a>
                </p>
              </div>
            </div>
            <Button
              active={agreement}
              name="Registrasi"
              type="primary"
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </Guest>
  );
};

export default Register;
