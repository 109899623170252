import Guest from "layout/Guest";
import MovieDetail from "parts/MovieDetail";
import MovieTime from "parts/MovieTime";
import TheaterDetail from "parts/TheaterDetail";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import screening from "constants/api/screening";
import NotFound from "./NotFound";
import moment from "moment";
const ShowTime = () => {
  const [showTime, setShowTime] = useState([]);
  const location = useLocation();
  const theater = location.state?.theater;
  const movie = location.state?.movie;
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  useEffect(() => {
    // Update the currentDateTime state every second
    const interval = setInterval(() => {
      setCurrentDateTime(moment());
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (theater && movie) {
      screening
        .get({ movie: movie.id, branch: theater.id, startDate: currentDateTime.format('YYYY-MM-DD'), endDate: currentDateTime.clone().add(2, 'days').format('YYYY-MM-DD') })
        .then((res) => {
          const data = res.data.result.data;
          const showTime = data
            .filter(
              (ele, ind) =>
                ind === data.findIndex((elem) => elem.date === ele.date)
            )
            .map((item) => {
              const time = data
                .filter((item2) => {
                  return item2.date == item.date;
                })
                .map((datatimescreening) => {
                  return datatimescreening;
                });
              return { ...item, times: time, theater: theater };
            });
          setShowTime(showTime);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <Guest>
      {theater && movie ? (
        <div className="pt-5 pb-24 bg-white">
          <div className="flex justify-center">
            <div className="flex flex-col md:w-1/2 w-full space-y-4">
              <MovieDetail data={movie} />
              <TheaterDetail data={theater} />
              <div className="w-full py-8">
                <div className="border-b-2"></div>
              </div>

              <div className="flex flex-col space-y-8 px-8">
                {showTime.map((show, i) => (
                  <MovieTime
                    key={i}
                    date={show.date}
                    price={show.currentPricing}
                    times={show.times}
                    data={show}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </Guest>
  );
};

export default ShowTime;
