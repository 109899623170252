import axios from "configs/axios/v1/index";
export default {
  // get: (data) => axios.get(`/screening?filter=movieId:${data.movie}&startDate=${data.startDate}&endDate=${data.endDate}`,{
    get: (data) => axios.get(`/screening?filter=movieId:${data.movie}&startDate=${data.startDate}`,{
    headers: {
      'dakota-branch-id': data.branch
    }
  }),
  // movie: (data) => axios.get(`/screening/movie?type=${data.type}`,{
  //   headers: {
  //     'dakota-branch-id': data.branch
  //   }
  // }),
  movie: (data) => axios.get(`/movie`,{
    headers: {
      'dakota-branch-id': data.branch
    }
  }),
  // fetchTime: (options = { params: {} }) =>
  //   axios.post(`/TimeScreening`, options),
  // fetchAllDate: (options = { params: {} }) =>
  //   axios.post(`/DateAllScreening`, options),
  // fetchAllTime: (options = { params: {} }) =>
  //   axios.post(`/TimeAllScreening`, options),
  // fetchDetail: (options = { params: {} }) =>
  //   axios.post(`/ScreeningDetail`, options),
  // fetchSeatDetail: (options = { params: {} }) =>
  //   axios.post(`/ScreeningSeatDetail`, options),
  // fetchAllDate: (idTheater, ) => axios.post("/DateScreening", { idTheater: id, idMovie }),
  // fetchTime: (id) => axios.post("/TimeAllScreening", { idTheater: id }),
};
