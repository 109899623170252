import React, { useEffect, useState } from "react";

const TheaterDetail = ({ data }) => {
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(storedFavorites);
  }, []);

  const handleAddToFavorites = () => {
    if (favorites.includes(data.id)) {
      const updatedFavorites = favorites.filter((id) => id !== data.id);
      setFavorites(updatedFavorites);
      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    } else {
      const updatedFavorites = [...favorites, data.id];
      setFavorites(updatedFavorites);
      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    }
  };

  const isFavorite = favorites.includes(data.id);

  return (
    <div className="flex flex-col justify-center space-y-4 px-8">
      <div className="w-2/3">
        <h3 className="font-semibold text-l">{data.name}</h3>
        <h3 className="font-light text-base">
          {data.street}, {data.city}, {data.province} {data.zip}
        </h3>
      </div>
      <div className="flex items-center space-x-4 font-light text-sm">
        <div className="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={isFavorite ? "#F3BB00" : "none"}
            stroke={isFavorite ? "#F3BB00" : "#CCCCCC"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`mr-2 w-6 h-6 cursor-pointer hover:opacity-70`}
            onClick={handleAddToFavorites}
          >
            <path d="M12 21.35l-1.45-1.32C5.4 16.36 2 13.25 2 9.5 2 6.42 4.42 4 7.5 4c1.38 0 2.73.56 3.75 1.56C12.77 5.44 14.12 4.88 15.5 4c3.08 0 5.5 2.42 5.5 5.5 0 3.75-3.4 6.86-8.55 10.54L12 21.35z" />
          </svg>
          Tambahkan sebagai favorit
        </div>
        <div className="flex">
          <img
            src={require("../assets/images/Location2.png")}
            alt="Location"
            className="mr-2"
          />
          Lokasi
        </div>
      </div>
    </div>
  );
};

export default TheaterDetail;
