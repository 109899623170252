import orders from "constants/api/orders";
import Guest from "layout/Guest";
import moment from "moment";
import Button from "parts/Button";
import React, { createRef, useEffect, useState } from "react";
import { createFileName, useScreenshot } from "use-react-screenshot";
import { useLocation } from "react-router-dom";
import NotFound from "./NotFound";
import Loading from "./Loading";
import QRCode from 'react-qr-code';

const DetailTransaction = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState([]);
  const location = useLocation();
  const data = location.state;


  // const checkOrder = () => {
  //   orders
  //     .detail({ id: data.data.id, branch: data.theaterId })
  //     .then((res) => {
  //       setOrder(res.data.result);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   checkOrder();
  // }, []);
  
  return (
    <Guest>
      {
      !data ? (
          <Loading />
        ) : (
          <div className="pt-5 pb-24 bg-white">
            <div className="flex justify-center mx-4">
              <div className="flex flex-col w-full md:w-1/2 space-y-8 items-center">
                <QRCode value={data.data.code} />
                <h3 className="font-semibold text-md">{data.data.code}</h3>
                <p>Scan QR Code ini untuk mendapatkan tiket masuk</p>
              </div>
            </div>
          </div>
        )
      } 
    </Guest>
  );
};

export default DetailTransaction;
