import Member from "layout/Member";
import React from "react";

const Requirement = () => {
  return (
    <Member>
      <div className="flex justify-center bg-white h-full py-20">
        <div className="w-1/2">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque modi
            corporis quia eos aliquid reiciendis pariatur consequatur numquam
            accusantium, placeat amet quas earum voluptate iure aperiam itaque
            atque dolore. Nemo corrupti odit quidem magni nostrum iste impedit
            consectetur tempora eum rerum autem soluta, eius magnam. Asperiores
            ipsum quibusdam repellat quaerat provident fugiat consequuntur
            ratione impedit iure accusantium aliquam perspiciatis illo saepe vel
            adipisci, quisquam voluptates distinctio aperiam. Quas et eligendi
            unde quasi omnis praesentium laboriosam fuga vero eaque vitae! Optio
            sed quisquam amet sint dolorem, quaerat expedita at incidunt velit
            accusamus ducimus repellat ratione blanditiis iusto excepturi
            reiciendis aliquid eligendi recusandae dignissimos, aliquam eveniet
            voluptatem in. Possimus deserunt optio expedita! Nihil iusto quas
            consectetur sunt dolorum nulla temporibus omnis rem, excepturi ex
            incidunt. Optio mollitia magnam ab esse accusamus officia quaerat
            dolorum vero est, eius doloremque consequuntur quidem quod, cumque
            quis libero odit voluptatem, quas ex earum veritatis. Ipsum dolore
            consequuntur quo cum ipsam natus libero aspernatur dolorum, nostrum
            architecto, nihil laborum reprehenderit eligendi! Eveniet aliquid
            quae doloribus aspernatur provident libero! Nihil sunt sapiente iste
            id voluptatum deleniti, sequi voluptas reiciendis quibusdam ipsa
            eaque exercitationem voluptatibus quisquam laborum, dolor ab sed
            quia aperiam. Deleniti quis voluptatum excepturi eius at dolor.
          </p>
        </div>
      </div>
    </Member>
  );
};

export default Requirement;
