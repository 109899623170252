import Guest from "layout/Guest";
import Button from "parts/Button";
import React, { useEffect } from "react";
import seat from "constants/api/seat";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cart from "constants/api/cart";
import NotFound from "./NotFound";
import Countdown from "parts/Countdown";
const ChooseSeat = () => {
  const [dataSeat, setSeat] = useState([]);
  const [totalSeat, setTotalSeat] = useState(0);
  const [maxSeat, setMaxSeat] = useState(6);
  const [expiredAt, setExpiredAt] = useState({
    hours: "",
    minutes: "",
  });
  const [seats, setSeats] = useState([]);
  const location = useLocation();
  const data = location.state;
  const idScreening = data?.time.id;
  const idTheater = data?.theater.id;
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("user")).id;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (data) {
      getSeat();
    }
  }, []);

  const getSeat = () => {
    seat
      .get({
        screeningId: idScreening,
        branch: idTheater,
      })
      .then((res) => {
        const dataSeats = res.data?.result;
        let select = [];
        let exp = [];
        dataSeats.map((row) => {
          row
            .filter(
              (item) => item.status === "select" && item.user?.id === userId
            )
            .map((col) => {
              select.push(col.seat.name);
              exp.push(col.expiredAt);
            });
        });
        const smallestTime = Math.min(
          ...exp.map((time) => {
            const [minutes, seconds] = time.split(":");
            return parseInt(minutes) * 60 + parseInt(seconds);
          })
        );

        const smallestMinutes = Math.floor(smallestTime / 60);
        const smallestSeconds = smallestTime % 60;

        const smallestTimeString = `${smallestMinutes
          .toString()
          .padStart(2, "0")}:${smallestSeconds.toString().padStart(2, "0")}`;

        const [hours, minutes] = smallestTimeString
          .split(":")
          .map((str) => parseInt(str));
        setExpiredAt({
          hours: hours,
          minutes: minutes,
        });
        setSeat(dataSeats);
        setSeats(select);
      })
      .catch((err) => {
        console.log(err);
      });
    // seat
    //   .columnSeat({
    //     idTheater: idTheater,
    //     idStudio: idStudio,
    //   })
    //   .then((res) => {
    //     setColumnSeat(res.data.data[0].column);
    //     seat
    //       .all({
    //         idTheater: idTheater,
    //         idStudio: idStudio,
    //       })
    //       .then((res) => {
    //         const allSeat = res.data.data;
    //         seat
    //           .preOrder({
    //             idTheater: idTheater,
    //             screeningId: idScreening,
    //           })
    //           .then((res) => {
    //             const preOrder = res.data.data;
    //             const seatBooked = allSeat.map((item) => {
    //               const isBooked = preOrder.find(
    //                 (seat) => seat.seat_id === item.id
    //               );
    //               const booked = preOrder.filter(
    //                 ({ seat_id }) => seat_id === item.id
    //               );
    //               const disabled = preOrder.find(
    //                 (seat) =>
    //                   seat.seat_id === item.id && seat.user_id !== userId
    //               );
    //               const seatStatus = !booked.length
    //                 ? 1
    //                 : booked[0].seatStatus === 2
    //                 ? 0
    //                 : 1;
    //               return {
    //                 ...item,
    //                 isBooked: isBooked ? true : false,
    //                 disabled: disabled || !seatStatus ? true : false,
    //                 booked: booked,
    //               };
    //             });
    //             setSeat(seatBooked);
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const selectSeat = (value) => {
    const data = {
      screeningId: idScreening,
      seatIds: [value.seat.id],
    };
    if (value.status === "available") {
      seat
        .select({ param: data, branch: idTheater })
        .then((res) => {
          setTotalSeat(totalSeat + 1);
          getSeat();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      seat
        .unselect({ param: data, branch: idTheater })
        .then((res) => {
          setTotalSeat(totalSeat - 1);
          getSeat();
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // if (value.isBooked) {
    //   const dataDelete = {
    //     idTheater: idTheater,
    //     screeningId: idScreening,
    //     seatNo: value.seatNo,
    //     seatStatus: 1,
    //   };
    //   seat
    //     .deletePreOrder(dataDelete)
    //     .then((res) => {
    //       setTotalSeat(totalSeat - 1);
    //       // getSeat();
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else {
    //   const dataSave = {
    //     idTheater: idTheater,
    //     seatId: value.id,
    //     screeningId: idScreening,
    //     seatStatus: 1,
    //     createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
    //     userId: userId,
    //   };
    //   seat
    //     .savePreOrder(dataSave)
    //     .then((res) => {
    //       setTotalSeat(totalSeat + 1);
    //       // getSeat();
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    //   // if (totalSeat < maxSeat) {
    //   // } else {
    //   //   alert("Maximal 6 seats");
    //   // }
    // }
  };

  const handleBuyNow = (e) => {
    e.preventDefault();
    // navigate("/keranjang", {
    //   state: {
    //     data: 'res',
    //   },
    // });
    seat
      .get({
        screeningId: idScreening,
        branch: idTheater,
      })
      .then((res) => {
        const data = res.data.result;
        var seats = [];
        var carts = [];
        data.map((row) => {
          row
            .filter(
              (item) => item.status === "select" && item.user?.id === userId
            )
            .map((col) => {
              seats.push({
                type: "ticket",
                screeningId: idScreening,
                seatId: col.seat.id,
              });
              carts.push({
                screeningId: idScreening,
                seatId: col.seat.id,
              });
            });
        });
        cart
          .save({
            param: { type: "ticket", items: carts },
            branch: idTheater,
          })
          .then((res) => {
            navigate("/keranjang");
          })
          .catch((err) => {
            console.log(err);
          });
        // const order = orders
        //   .ticket({ param: { items: seats }, branch: idTheater })
        //   .then((res) => {
        //     console.log(res);
        //     navigate("/keranjang", {
        //       state: {
        //         data: res.data,
        //       },
        //     });
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      })
      .catch((err) => {
        console.log(err);
      });
    // seat
    //   .preOrder({
    //     idTheater: idTheater,
    //     screeningId: idScreening,
    //   })
    //   .then((res) => {
    //     const preOrder = res.data.data.filter(
    //       (item) => item.user_id === userId
    //     );
    //     seat
    //       .all({ idTheater: idTheater, idStudio: idStudio })
    //       .then((res) => {
    //         const allSeat = res.data.data;
    //         const seatBooked = preOrder.map((item) => {
    //           const seat = allSeat.find((seat) => seat.id === item.seat_id);
    //           return {
    //             ...item,
    //             seatNo: seat.seatNo,
    //           };
    //         });
    //         const seatNo = seatBooked.map((item) => `'${item.seatNo}'`);
    //         console.log(seatNo);
    //         const dataBuy = {
    //           idTheater: idTheater,
    //           screeningId: idScreening,
    //           seatStatus: 2,
    //           updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
    //           userId: userId,
    //           seatNo: `${seatNo}`,
    //         };
    //         seat
    //           .updaatePreOrder(dataBuy)
    //           .then((res) => {
    //             navigate("/keranjang", {
    //               state: {
    //                 data: data,
    //                 booked: seatBooked,
    //               },
    //               replace: true,
    //             });
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   });
  };

  return (
    <Guest>
      {data ? (
        <div className="pt-10 pb-20 bg-white">
          <div className="flex justify-center">
            <div className="flex flex-col w-full md:w-auto space-y-8 items-center">
              <h3 className="font-semibold text-2xl">Pilih Kursi</h3>
              <div className="flex gap-8">
                <div className="flex gap-2">
                  <div className="w-8 h-8 bg-[#a5a8a5]"></div>
                  <p className="flex items-center font-light">Tidak Tersedia</p>
                </div>
                <div className="flex gap-2">
                  <div className="w-8 h-8 bg-[#ff3e24]"></div>
                  <p className="flex items-center font-light">Terisi</p>
                </div>
                <div className="flex gap-2">
                  <div className="w-8 h-8 bg-[#2ad13b]"></div>
                  <p className="flex items-center font-light">Tersedia</p>
                </div>
              </div>
              <div className="flex gap-8">
                <div className="flex gap-2">
                  <div className="w-8 h-8 bg-[#f7a10c]"></div>
                  <p className="flex items-center font-light">
                    Sedang Dipilih Orang Lain
                  </p>
                </div>
                <div className="flex gap-2">
                  <div className="w-8 h-8 bg-[#ffe924]"></div>
                  <p className="flex items-center font-light">Dipilih</p>
                </div>
              </div>
              <div className="flex w-full bg-slate-200 p-2 rounded-md justify-between">
                <p className="font-light text-sm">
                  Dipilih:{" "}
                  {seats.length > 0 && (
                    <span className="underline">{seats.join(", ")}</span>
                  )}
                </p>
                {seats.length > 0 && <Countdown startTime={{ ...expiredAt }} />}
              </div>
              <div className="flex-col space-y-2">
                {dataSeat.length > 0
                  ? dataSeat?.map((item, index) => {
                      return (
                        <div className="flex gap-2" style={{flexDirection: 'row-reverse'}} key={index}>
                          {item.map((seat, index) => {
                            var seatActive = "";
                            var me = true;
                            if (seat.seat.isActive) {
                              seatActive = "bg-[#2ad13b]";
                            }
                            if (seat.status === "select") {
                              seatActive = "bg-[#ffe924]";
                              if (seat.user.id !== userId) {
                                seatActive = "bg-[#f7a10c]";
                                me = false;
                              }
                            }
                            if (
                              seat.status === "book" ||
                              seat.status === "order"
                            ) {
                              seatActive = "bg-[#ff3e24]";
                            }
                            return (
                              <button
                                key={index}
                                onClick={() => {
                                  selectSeat(seat);
                                }}
                                disabled={
                                  !seat.seat.isActive ||
                                  !me ||
                                  seat.status === "book" ||
                                  seat.status === "order"
                                }
                              >
                                <div
                                  className={`p-2 md:p-2 min-w-full ${seatActive} rounded-sm text-gray-800 text-xs w-8`}
                                >
                                  {!seat.seat.isActive ||
                                  !me ||
                                  seat.status === "book" ||
                                  seat.status === "order"
                                    ? ""
                                    : seat.seat.name}
                                </div>
                              </button>
                            );
                          })}
                        </div>
                      );
                    })
                  : ""}
              </div>
              <div className="w-full px-10">
                <div className="p-2 bg-[#AAAAAA] font-light flex justify-center">
                  Layar
                </div>
              </div>

              {/* <div
              className={[
                "grid gap-y-4 gap-x-4 content-center px-8",
                colSeat(columnSeat),
              ].join(" ")}
            >
              {dataSeat.length > 0
                ? dataSeat?.map((item, index) => {
                    var statusSeat = "bg-[#AAAAAA]";
                    if (item.statusCrash) {
                      statusSeat = "";
                    } else {
                      statusSeat = "bg-[#AAAAAA]";
                      if (item.isBooked && item.booked.length > 0) {
                        if (
                          item.booked[0].user_id === userId &&
                          item.booked[0].seatStatus === 1
                        ) {
                          statusSeat = "bg-secondary";
                        } else {
                          statusSeat = "bg-[#F30000]";
                        }
                      }
                    }
                    return (
                      <>
                        <button
                          key={index}
                          onClick={() => {
                            selectSeat(item);
                          }}
                          disabled={item.statusCrash || item.disabled}
                          // className={`p-2 md:p-3 min-w-full ${statusSeat}`}
                          className=""
                        >
                          <div
                            className={`p-2 md:p-3 min-w-full ${statusSeat} rounded-sm`}
                          ></div>
                        </button>
                      </>
                    );
                  })
                : "Belum tersedia"}
            </div> */}
              <div className="flex flex-col space-y-4">
                {/* <Button
                name="Tambahkan ke Keranjang"
                type="primary"
                href="/keranjang"
              /> */}
                <Button
                  // href={"/keranjang"}
                  name="Beli Sekarang"
                  type="primary"
                  data={data}
                  onClick={handleBuyNow}
                  active={seats.length}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </Guest>
  );
};

export default ChooseSeat;
