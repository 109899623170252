import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";

import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";

function Navbar({ active, isLogin = false }) {
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const headerWithCart = ["/home", "/segera", "/teater", "/kafetaria"];
  const [sideBar, setSideBar] = useState(false);
  const sidebarRef = useRef(null);
  const Title = () => {
    switch (active) {
      case "/":
        return (
          <h3 className="font-semibold text-lg justify-self-center bg-primary text-white bg-[#878787]">
            Profil
          </h3>
        );
      case "/ubah-profil":
        return (
          <h3 className="font-semibold text-lg justify-self-center bg-primary text-white bg-[#878787]">
            Ubah Profil
          </h3>
        );
      case "/ubah-kata-sandi":
        return (
          <h3 className="font-semibold text-xs justify-self-center bg-primary text-white bg-[#878787]">
            Ubah Kata Sandi
          </h3>
        );
      case "/transaksi":
        return (
          <h3 className="font-semibold text-lg justify-self-center bg-primary text-white bg-[#878787]">
            Transaksi
          </h3>
        );
      case "/hubungi-kami":
        return (
          <h3 className="font-semibold text-sm justify-self-center bg-primary text-white bg-[#878787]">
            Hubungi Kami
          </h3>
        );
      case "/persyaratan-layanan":
        return (
          <h3 className="font-semibold text-xs justify-self-center bg-primary text-white bg-[#878787]">
            Persyaratan Layanan
          </h3>
        );
      default:
        return (
          <img
            src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-default-white.svg`}
            alt="Logo"
            className="justify-self-center h-10"
          />
        );
    }
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("user"));
    setProfile(user);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSideBar(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sidebarRef]);
  return (
    <>
      <div className="sticky top-0 w-screen bg-[#878787] md:bg-white z-10">
        <div className="md:justify-between justify-center m-auto left-0 right-0 max-w-7xl w-full px-8 py-4 hidden md:flex">
          <div className="flex items-center">
            <Link to="/">
              <img
                className="h-12"
                src={require("../assets/images/logo-default.png")}
                alt="dakota"
              />
            </Link>
            <ul
              className={[
                "items-center fixed inset-0 bg-indigo-1000 pt-24 md:pt-0 md:bg-transparent md:relative md:flex md:opacity-100 md:visible",
              ].join(" ")}
            >
              <li className="leading-10">
                <Link
                  to="/home"
                  className={`hover:text-primary px-6 py-3 sm:my-0 text-base ${
                    active === "/home" ? "text-primary font-bold" : "font-light"
                  }`}
                >
                  Tayang
                </Link>
              </li>
              <li className="leading-10">
                <Link
                  to="/segera"
                  className={`hover:text-primary px-6 py-3 sm:my-0 text-base ${
                    active === "/segera"
                      ? "text-primary font-bold"
                      : "font-light"
                  }`}
                >
                  Segera
                </Link>
              </li>
              <li className="leading-10">
                <Link
                  to="/teater"
                  className={`hover:text-primary px-6 py-3 sm:my-0 text-base ${
                    active === "/teater"
                      ? "text-primary font-bold"
                      : "font-light"
                  }`}
                >
                  Teater
                </Link>
              </li>
              <li className="leading-10">
                <Link
                  to="/kafetaria"
                  className={`hover:text-primary px-6 py-3 sm:my-0 text-base ${
                    active === "/kafetaria"
                      ? "text-primary font-bold"
                      : "font-light"
                  }`}
                >
                  Kafetaria
                </Link>
              </li>
              {/* <li className="leading-10">
                <Link
                  to="/promotion"
                  className={`hover:text-primary px-6 py-3 sm:my-0 text-base ${
                    active === "/promotion"
                      ? "text-primary font-bold"
                      : "font-light"
                  }`}
                >
                  Promotion
                </Link>
              </li>
              <li className="leading-10">
                <Link
                  to="/investment"
                  className={`hover:text-primary px-6 py-3 sm:my-0 text-base ${
                    active === "/investment"
                      ? "text-primary font-bold"
                      : "font-light"
                  }`}
                >
                  Investment
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="flex items-center">
            <div className="pr-4">
              <Link to="/keranjang">
                <img
                  src={require("../assets/images/Shopping-Cart.png")}
                  alt="Shopping Cart"
                />
              </Link>
            </div>
            <div>
              {!token ? (
                <Button href="/login" name="Login" type="secondary" />
              ) : (
                <div className="flex flex-row items-center">
                  <Link to="/profile">
                    {profile.avatar ? (
                      <img
                        src={profile.avatar}
                        alt="Profile"
                        className="rounded-full w-10 h-10"
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/assets/images/profile.jpeg`}
                        alt="Profile"
                        className="rounded-full w-10 h-10"
                      />
                    )}
                  </Link>
                  <p className="ml-3">{profile.firstName}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 items-center m-auto left-0 right-0 max-w-7xl w-full px-8 py-4 md:hidden h-18">
          {headerWithCart.includes(active) ? (
            <Link to="/keranjang">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Shopping Cart B.png`}
                alt="Shopping Cart"
                className="justify-self-start w-9"
              />
            </Link>
          ) : (
            <img
              src={`${process.env.REACT_APP_BASE_URL}/assets/images/Back B.png`}
              alt="Back"
              className="justify-self-start w-9"
              onClick={() => navigate(-1)}
            />
          )}
          <Title />
          <img
            src={`${process.env.REACT_APP_BASE_URL}/assets/images/Menu B.png`}
            alt="Menu"
            className="justify-self-end w-9"
            onClick={() => {
              setSideBar(!sideBar);
            }}
          />
        </div>
        {/* {!pageWithOutHeader.includes(active) && (
        )} */}
      </div>
      <div className="fixed bottom-0 w-screen py-4 bg-[#878787] z-10 block md:hidden">
        <div className="grid grid-cols-5 gap-4 place-items-center">
          <Link to="/home" className="flex flex-col items-center">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/assets/images/Circled Play B.png`}
              alt="Profile"
              className="rounded-full w-9 h-9"
            />
            <h6 className="text-xs text-white">Tayang</h6>
          </Link>
          <Link to="/segera" className="flex flex-col items-center">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/assets/images/Video Playlist B.png`}
              alt="Profile"
              className="rounded-full w-9 h-9"
            />
            <h6 className="text-xs text-white">Segera</h6>
          </Link>
          <Link to="/teater" className="flex flex-col items-center">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/assets/images/Shutters B.png`}
              alt="Profile"
              className="rounded-full w-9 h-9"
            />
            <h6 className="text-xs text-white">Teater</h6>
          </Link>
          <Link to="/kafetaria" className="flex flex-col items-center">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/assets/images/Popcorn B.png`}
              alt="Profile"
              className="rounded-full w-9 h-9"
            />
            <h6 className="text-xs text-white">Kafetaria</h6>
          </Link>
          <Link to="/profile" className="flex flex-col items-center">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/assets/images/Male User B.png`}
              alt="Profile"
              className="rounded-full w-9 h-9"
            />
            <h6 className="text-xs text-white">Profile</h6>
          </Link>
        </div>
      </div>
      <div
        className={[
          "flex flex-col space-y-4 fixed w-full h-full bg-[#ffffff] bg-opacity-50 top-0 z-10 ease-in-out duration-300",
          sideBar ? "translate-x-" : "translate-x-full",
        ].join(" ")}
      >
        <div className="flex flex-col space-y-4 w-3/4 h-full top-0 right-0 fixed bg-[#EFEFEF]">
          <button
            className="top-4 right-8 absolute text-2xl text-[#000000] hover:cursor-pointer"
            onClick={() => setSideBar(!sideBar)}
          >
            X
          </button>
          <div className="flex pt-20 justify-center">
            <img
              className="h-12"
              src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-default.png`}
              alt="Logo"
            />
          </div>
          <div>
            {user && (
              <div className="flex flex-row gap-4 items-center p-4">
                {profile.avatar ? (
                  <img
                    src={profile.avatar}
                    alt="profile"
                    className="rounded-full w-20 h-20"
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/profile.jpeg`}
                    alt="profile"
                    className="rounded-full w-20 h-20"
                  />
                )}
                <div className="flex flex-col items-start">
                  <p className="font-semibold text-xs break-all">
                    {profile?.name}
                  </p>
                  <p className="font-light text-xs break-all">
                    {profile?.phone}
                  </p>
                  <p className="font-light text-xs break-all">
                    {profile?.email}
                  </p>
                  <p className="font-medium text-xs break-all">
                    DC Point: {profile.point}
                  </p>
                  {profile.depositNumber !== 0 && (
                    <p className="font-medium text-xs break-all">
                      Sisa Deposit: {profile.deposit}
                    </p>
                  )}
                </div>
              </div>
            )}
            <div>
              <div className="flex flex-col gap-4 px-6">
                <Link
                  to="/home"
                  className="flex items-center gap-2 font-light hover:text-primary"
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/Play Button Circled SB.png`}
                    alt="beranda"
                  />
                  Beranda
                </Link>
                {/* <Link
                  to="/ubah-kata-sandi"
                  className="flex items-center gap-2 font-light hover:text-primary"
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/Shutters SB.png`}
                    alt="name"
                  />
                  Teater Favorit
                </Link>
                <Link
                  to="/transaksi"
                  state="transaction"
                  className="flex items-center gap-2 font-light hover:text-primary"
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/Brake Warning SB.png`}
                    alt="name"
                  />
                  Yang baru Di Datkota
                </Link> */}
                <Link
                  to="/keranjang"
                  className="flex items-center gap-2 font-light hover:text-primary"
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/Shopping SB.png`}
                    alt="Shopping Cart"
                  />
                  Keranjang Belanja
                </Link>
                <Link
                  to="/transaksi"
                  state="transaction"
                  className="flex items-center gap-2 font-light hover:text-primary"
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/Train Ticket SB.png`}
                    alt="name"
                  />
                  Transaksi Aktif
                </Link>
                {/* <Link
                  to="/transaksi"
                  state="transaction"
                  className="flex items-center gap-2 font-light hover:text-primary"
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/Ask Question SB.png`}
                    alt="name"
                  />
                  FAQ
                </Link>
                <Link
                  to="/transaksi"
                  state="transaction"
                  className="flex items-center gap-2 font-light hover:text-primary"
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/Money Bag SB.png`}
                    alt="name"
                  />
                  Investment Offering
                </Link>
                <Link
                  to="/hubungi-kami"
                  className="flex items-center gap-2 font-light hover:text-primary"
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/Phone SB.png`}
                    alt="name"
                  />
                  Hubungi
                </Link> */}
                <p className="font-light">Ikuti Kami:</p>
                <div className="flex gap-4">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/IG SB.png`}
                    alt="IG"
                  />
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/FB SB.png`}
                    alt="FB"
                  />
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/images/TT SB.png`}
                    alt="TT"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Navbar.propTypes = {
  onLight: propTypes.bool,
};

export default Navbar;
