import Member from "layout/Member";
import Button from "parts/Button";
import React from "react";

const ContactUs = () => {
  return (
    <Member>
      <div className="flex justify-center items-center bg-white h-full py-20">
        <div className="w-fit space-y-10">
          <div className="flex">
            <img
              src={require("../assets/images/Whatsapp.png")}
              alt=""
              className="object-contain w-12 mr-3"
            />
            <div>
              <p>08123456789</p>
              <Button
                name="Mulai Obrolan"
                type="primary"
                onClick={() =>
                  window.open(
                    "https://wa.me/+628123456789",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              />
            </div>
          </div>
          <div className="flex">
            <img
              src={require("../assets/images/Email.png")}
              alt=""
              className="object-contain w-12 mr-3"
            />
            <div>
              <p>Email:</p>
              <p>cs@dakotacinema.com</p>
            </div>
          </div>
          <div className="flex">
            <img
              src={require("../assets/images/Instagram.png")}
              alt=""
              className="object-contain w-12 mr-3"
            />
            <div>
              <p>@dakota1</p>
              <p>@dakota2</p>
            </div>
          </div>
        </div>
      </div>
    </Member>
  );
};

export default ContactUs;
