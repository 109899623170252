import orders from "constants/api/orders";
import Guest from "layout/Guest";
import moment from "moment";
import Button from "parts/Button";
import React, { createRef, useEffect, useState } from "react";
import { createFileName, useScreenshot } from "use-react-screenshot";
import { useLocation } from "react-router-dom";
import NotFound from "./NotFound";
import Loading from "./Loading";

const HistoryPayment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState([]);
  const [qrcode, setQrcode] = useState(null);
  const location = useLocation();
  const data = location.state;
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const download = (image, { name = "img", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const getImage = (e) => {
    e.preventDefault();
    takeScreenshot(ref.current).then(download);
  };

  const checkOrder = () => {
    orders
      .detail({ id: data.data.id, branch: data.theaterId })
      .then((res) => {
        setOrder(res.data.result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    moment.locale("id");

    window.ReactNativeWebView?.postMessage('Wayne is coming again');

    fetch("https://api.dakotacinema.id/proxy?url=" + data.data.payment.qrCode)
      .then((response) => response.blob())
      .then((blob) => {
        const convertedBlob = new Blob([blob], { type: "image/jpeg" });
        const reader = new FileReader();
        reader.readAsDataURL(convertedBlob);
        reader.onloadend = () => {
          const imageDataUrl = reader.result;
          setQrcode(imageDataUrl);
        };
      });
    // setQrcode(data.payment.qrCode);
    checkOrder();
    const intervalId = setInterval(() => {
      checkOrder();
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  
  return (
    <Guest>
      {data ? (
        isLoading ? (
          <Loading />
        ) : // order.status === "settled" ? (
        //   <div className="flex flex-col items-center h-full pt-5 pb-24 bg-white">
        //     <svg
        //       className="w-20 h-20 text-green-500 mb-5"
        //       xmlns="http://www.w3.org/2000/svg"
        //       fill="none"
        //       viewBox="0 0 24 24"
        //       stroke="currentColor"
        //     >
        //       <path
        //         strokeLinecap="round"
        //         strokeLinejoin="round"
        //         strokeWidth="2"
        //         d="M5 13l4 4L19 7"
        //       />
        //     </svg>
        //     <h1 className="text-3xl font-bold mb-3">Pembayaran Berhasil!</h1>
        //     <p className="text-lg mb-8">
        //       Terima kasih telah melakukan pembayaran.
        //     </p>
        //     <Button
        //       name="Kembali ke Beranda"
        //       type="primary"
        //       href="/home"
        //       replace
        //     />
        //   </div>
        // ) :
        order.status === "expired" ? (
          <div className="flex flex-col items-center h-full pt-5 pb-24 bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
            >
              <circle cx="50" cy="50" r="45" fill="#FF0000" />
              <path
                d="M32 32L68 68M68 32L32 68"
                stroke="white"
                strokeWidth="8"
                strokeLinecap="round"
              />
            </svg>

            <h1 className="text-3xl font-bold mb-4">
              Pembayaran Telah Kedaluwarsa
            </h1>
            <p className="text-lg text-gray-600">
              Pembayaran Anda telah kedaluwarsa. Silakan lakukan pembayaran baru
              untuk melanjutkan.
            </p>
            <Button
              name="Kembali ke Beranda"
              type="primary"
              href="/home"
              replace
            />
          </div>
        ) : (
          <div className="pt-5 pb-24 bg-white">
            <div className="flex justify-center mx-4">
              <div className="flex flex-col w-full md:w-1/2 space-y-8 items-center">
                <h3 className="font-semibold text-2xl">Pembayaran</h3>
                <div ref={ref} className="flex-col p-4">
                  <div className="flex font-light text-sm gap-1 w-full">
                    <img
                      src={require("../assets/images/Time.png")}
                      alt="Time"
                      className="w-6 h-6"
                    />
                    QR Code ini hanya berlaku hingga{" "}
                    <span className="font-semibold">
                      {order.payment.expiredAt.replace("T", " ")}
                    </span>
                  </div>
                  <p>Apabila telah membayar, mohon tunggu sekitar 1 menit dan jangan keluar dari halaman ini</p>
                  <div className="flex justify-between w-full items-center">
                    <div>
                      <img
                        src={require("../assets/images/qris.png")}
                        alt="qris"
                      />
                    </div>
                    <img src={require("../assets/images/gpn.png")} alt="gpn" />
                  </div>
                  <div className="flex flex-col items-center">
                    <p>Dakota Cinema</p>
                    <p className="font-bold">{data.subTotalAmount}</p>
                  </div>
                  <div className="flex justify-center w-full">
                    <img
                      //  src={require("../assets/images/qrcode.png")}
                      src={qrcode}
                      className="w-1/2 p-2"
                      alt="qr"
                    />
                  </div>
                  <div className="text-center">
                    {/* <p>
                    Berlaku hingga{" "}
                    {moment().add(2, "hours").format("DD MMMM YYYY, hh:mm")}
                  </p> */}
                    <p>Kami menerima pembayaran dari aplikasi berikut:</p>
                    <div className="flex justify-center items-center gap-4 p-2">
                      <div>
                        <img
                          src={require("../assets/images/link-aja.png")}
                          alt="Link Aja"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/gopay.png")}
                          alt="GoPay"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/ovo.png")}
                          alt="OVO"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/dana.png")}
                          alt="DANA"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/shopee-pay.png")}
                          alt="Shopee Pay"
                        />
                      </div>
                    </div>
                    <p>
                      dan melalui semua aplikasi pembayaran yang <br />{" "}
                      mendukung QRIS
                    </p>
                  </div>
                </div>
                <div className="px-28 mt-4">
                  <Button name="Unduh QRIS" type="primary" onClick={getImage} />
                </div>
                <p className="font-medium text-start w-full px-24">
                  Bagaimana cara membayar <br />
                  menggunakan QRIS?
                </p>
                <ul className="list-disc px-28 font-light text-sm mt-5">
                  <li>
                    Tekan tombol <span className="font-bold">Unduh QRIS</span>{" "}
                    atau <span className="font-bold">screenshot</span> untuk
                    menyimpan QR Code ke handphone Anda
                  </li>
                  <li>
                    Buka aplikasi pembayaran yang mendukung pembayaran melalui
                    QRIS
                  </li>
                  <li>
                    Masukkan gambar QRIS yang telah diunduh sebelumnya (biasanya
                    ada di folder “Download”)
                  </li>
                  <li>
                    Cek semua detail pembayaran, apabila telah sesuai maka
                    lakukan pembayaran
                  </li>
                  <li>
                    Apabila transaksi telah berhasil, buka aplikasi Dakota
                    Cinema dan cek transaksi Anda di Riwayat Transaksi
                  </li>
                </ul>
              </div>
            </div>
            {order.status === "settled" && (
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div
                  className="fixed inset-0 bg-black opacity-50"
                  // onClick={onClose}
                ></div>
                <div className="bg-white rounded-lg p-8 max-w-md relative flex-row text-center">
                  <h2 className="text-2xl text-primary">Pembayaran</h2>
                  <h2 className="text-5xl text-primary">SUKSES</h2>
                  <p className="mb-4 text-primary">
                    Cek tiket di riwayat transaksi
                  </p>
                  <div className="flex flex-col space-y-2">
                    <Button
                      name="Pesan Makanan & Minuman Disini"
                      type="primary"
                      href="/kafetaria-menu"
                      data={data.theater}
                      replace
                    />
                    <Button name="Tutup" type="light" href="/home" replace />
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        <NotFound />
      )}
    </Guest>
  );
};

export default HistoryPayment;
