import {
  FETCH_MOVIES,
  SELECT_MOVIE,
  STATUS_MOVIES,
  MESSAGE_MOVIE,
} from "constants/types/movies";

const initialState = {
  data: {},
  total: 0,
  status: "idle",
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STATUS_MOVIES:
      return {
        ...state,
        status: action.payload,
      };

    case FETCH_MOVIES:
      return {
        ...state,
        data:
          action.payload?.reduce?.((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {}) ?? {},
        total: action.payload?.length ?? 0,
        status: "ok",
      };

    case SELECT_MOVIE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            ...action.payload,
          },
        },
        status: "ok",
      };

    case MESSAGE_MOVIE:
      return {
        ...state,
        message: action.payload,
        status: "error",
      };

    default:
      return state;
  }
}
