import { combineReducers } from "redux";

import movies from "./movies";
import moviesCS from "./movies-cs";
import theaters from "./theaters";
export default combineReducers({
  movies,
  moviesCS,
  theaters,
});
