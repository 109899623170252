import Guest from "layout/Guest";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import theaters from "constants/api/theaters";
import NotFound from "./NotFound";

const ChooseTheater = () => {
  const [theater, setTheater] = useState([]);
  const location = useLocation();
  const movie = location.state;
  useEffect(() => {
    window.scrollTo(0, 0);
    if (movie) {
      theaters
        .fl(movie.id)
        .then((res) => {
          setTheater(res.data.result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <Guest>
      {movie ? (
        <div className="py-20 bg-white">
          <div className="flex justify-center">
            <div className="flex flex-col w-full md:w-1/3 px-8 md:px-0 space-y-4 items-center">
              <h3 className="font-semibold text-2xl">Pilih Teater</h3>
              <input
                type="text"
                className="border rounded-xl w-full h-10 bg-slate-200 pl-4 focus:outline-none font-extralight"
                placeholder="Cari Lokasi Teater"
              />

              <div className="flex flex-col w-full space-y-2 pl-4">
                {theater?.map?.((item, index) => {
                  return (
                    <Link
                      to="/jam-tayang"
                      key={index}
                      state={{ theater: item, movie: movie }}
                    >
                      <p className="font-light hover:bg-slate-200 p-2 rounded-lg">
                        {item.name}
                      </p>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </Guest>
  );
};

export default ChooseTheater;
