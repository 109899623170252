import users from "constants/api/users";
import Member from "layout/Member";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(user);
    setProfile(user);
    // users
    //   .all()
    //   .then((res) => {
    //     console.log(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  // const navigation = useNavigate();
  // useEffect(() => {
  //   console.log(window.location.pathname);
  //   const path = window.location.pathname;
  //   navigation(path);
  // }, [show]);

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <Member>
      <div className={`w-full pb-24 left-0 top-0 h-full bg-white`}>
        <div className="flex flex-col md:flex-row gap-4 items-center p-10 ml-10">
          {profile.avatar ? (
            <img
              src={profile.avatar}
              alt="profile"
              className="rounded-full w-20 h-20"
            />
          ) : (
            <img
              src={`${process.env.REACT_APP_BASE_URL}/assets/images/profile.jpeg`}
              alt="profile"
              className="rounded-full w-20 h-20"
            />
          )}
          <div className="flex flex-col items-center md:items-start">
            <p className="font-semibold text-xs break-all">
              {profile.firstName + " " + profile.lastName}
            </p>
            <p className="font-light text-xs break-all">{profile.phone}</p>
            <p className="font-light text-xs break-all">{profile.email}</p>
            <p className="font-medium text-xs break-all">
              DC Point: {profile.point}
            </p>
            {profile.depositNumber != 0 ? 
              <p className="font-medium text-xs break-all">
                Deposit: {profile.deposit}
              </p>
              : null
            }
            {profile.depositNumber !== 0 && (
              <p className="font-medium text-xs break-all">
                Sisa Deposit: {profile.deposit},-
              </p>
            )}
          </div>
        </div>
        <div>
          <p className="font-semibold my-4 p-2 bg-[#EFEFEF] pl-12">Akun Saya</p>
          <div className="flex flex-col gap-4 px-6 pl-12">
            <Link
              to="/ubah-profil"
              className="flex items-center gap-2 font-light hover:text-primary"
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Name.png`}
                alt="name"
              />
              Ubah Profil
            </Link>
            <Link
              to="/ubah-kata-sandi"
              className="flex items-center gap-2 font-light hover:text-primary"
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Unlock.png`}
                alt="name"
              />
              Ubah Kata Sandi
            </Link>
          </div>
          <p className="font-semibold my-4 p-2 bg-[#EFEFEF] pl-12">Transaksi</p>
          <div className="flex flex-col gap-4 px-6 pl-12">
            <Link
              to="/transaksi"
              state="transaction"
              className="flex items-center gap-2 font-light hover:text-primary"
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Train Ticket.png`}
                alt="name"
              />
              Transaksi Aktif
            </Link>
            <Link
              to="/riwayat-transaksi"
              state="history"
              className="flex items-center gap-2 font-light hover:text-primary"
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Activity History.png`}
                alt="name"
              />
              Riwayat Transaksi
            </Link>
          </div>
          <p className="font-semibold my-4 p-2 bg-[#EFEFEF] pl-12"></p>
          <div className="flex flex-col gap-4 px-6 pl-12">
            <Link
              to="/hubungi-kami"
              className="flex items-center gap-2 font-light hover:text-primary"
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Phone.png`}
                alt="name"
              />
              Hubungi
            </Link>
            <Link
              to="/persyaratan-layanan"
              className="flex items-center gap-2 font-light hover:text-primary"
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Terms and Conditions.png`}
                alt="name"
              />
              Persyaratan Layanan / Penafian
            </Link>
            <li className="flex items-center gap-2 font-light hover:text-primary">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Police Badge.png`}
                alt="name"
              />
              Kebijakan Pribadi
            </li>
            {/* <li className="flex items-center gap-2 font-light hover:text-primary">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Remove.png`}
                alt="name"
              />
              Hapus Akun
            </li> */}
            <li className="flex items-center gap-2 font-light hover:text-primary">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/Logout Rounded.png`}
                alt="name"
              />
              <button onClick={logout}>Logout</button>
            </li>
          </div>
        </div>
      </div>
    </Member>
  );
};

export default Profile;
