import Guest from "layout/Guest";
import React, { useEffect } from "react";

const Investment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Guest>
      <div className="pb-20 bg-white">
        <div className="flex flex-col space-y-10 justify-center">
          <div className="h-screen bg-slate-200"></div>
          <p className="px-36">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Inventore
            qui reiciendis dolorum rerum eaque atque nulla dicta provident in!
            Laborum, labore ullam et minus nulla dolor ipsa earum? Facilis ea
            iste ducimus. Pariatur tempore amet laboriosam necessitatibus ab
            fuga. Earum mollitia consequuntur amet quod est aliquid dicta,
            blanditiis perspiciatis asperiores sint, cumque impedit eligendi
            laudantium dolores corrupti? Illo voluptatibus sequi perferendis
            eligendi laborum optio beatae nemo nesciunt, ea laudantium magni
            sunt aut adipisci autem sed distinctio odio! Quos aspernatur quidem
            eos sunt, iusto nulla numquam quia, nihil dolorum ullam fugit
            voluptates aperiam deserunt corrupti, ipsam commodi itaque vero fuga
            modi.
          </p>
        </div>
      </div>
    </Guest>
  );
};

export default Investment;
