import axios from "configs/axios/v1/index";
export default {
  create: (data) =>
    axios.post(`/order`, data.param, {
      headers: { "dakota-branch-id": data.branch },
    }),
  pay: (data) =>
    axios.post(`/order/${data.id}/payment`, data.param, {
      headers: { "dakota-branch-id": data.branch },
    }),
  detail: (data) =>
    axios.get(`/order/${data.id}`, {
      headers: {
        "dakota-branch-id": data.branch,
      },
    }),
  all: (data) =>
    axios.get('/order', {
      headers: {
        "dakota-branch-id": data.branch,
      },
    }),
  history: (data) =>
    axios.get('/order/history?sort=date:desc', {
      headers: {
        "dakota-branch-id": data.branch,
      },
    }),
};
