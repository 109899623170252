import {
  FETCH_THEATERS,
  FETCH_FLTHEATERS,
  STATUS_THEATERS,
  MESSAGE_THEATER,
} from "constants/types/theaters";

const initialState = {
  data: {},
  total: 0,
  status: "idle",
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STATUS_THEATERS:
      return {
        ...state,
        status: action.payload,
      };

    case FETCH_THEATERS:
      return {
        ...state,
        data:
          action.payload?.reduce?.((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {}) ?? {},
        total: action.payload?.length ?? 0,
        status: "ok",
      };

    case FETCH_FLTHEATERS:
      return {
        ...state,
        data:
          action.payload?.reduce?.((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {}) ?? {},
        total: action.payload?.length ?? 0,
        status: "ok",
      };

    case MESSAGE_THEATER:
      return {
        ...state,
        message: action.payload,
        status: "error",
      };

    default:
      return state;
  }
}
