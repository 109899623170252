import React from "react";
import { Link } from "react-router-dom";

const Button = ({
  href,
  name,
  type,
  active = true,
  data = [],
  onClick,
  replace,
}) => {
  const linkProps = {
    to: href,
    state: data,
    onClick: onClick,
  };

  if (replace) {
    linkProps.replace = true;
  }
  return (
    <>
      {type === "primary" ? (
        <Link {...linkProps}>
          <button
            className={
              active
                ? "bg-primary text-white hover:opacity-70 transition-all duration-200 focus:outline-none shadow-inner px-6 py-1 w-full rounded-lg font-medium text-base"
                : "bg-[#F4F4F4] px-6 py-1 w-full rounded-lg font-medium text-base text-white"
            }
            {...(active ? {} : { disabled: true })}
          >
            {name}
          </button>
        </Link>
      ) : type === "secondary" ? (
        <Link {...linkProps}>
          <button className="bg-secondary hover:opacity-70 transition-all duration-200 focus:outline-none shadow-inner px-6 py-1 w-full rounded-lg font-medium text-base">
            {name}
          </button>
        </Link>
      ) : type === "light" ? (
        <Link {...linkProps}>
          <button className="bg-white hover:opacity-70 transition-all duration-200 focus:outline-none shadow-inner px-6 py-1 w-full rounded-lg font-medium text-base text-primary border border-primary ">
            {name}
          </button>
        </Link>
      ) : type === "time" ? (
        <Link to={href} state={data}>
          <button
            className={
              active
                ? "bg-[#D9D9D9] hover:bg-secondary transition-all duration-200 focus:outline-none p-3 w-fit font-light text-base"
                : "bg-[#F4F4F4] p-3 w-fit font-light text-base text-[#C0C0C0]"
            }
            {...(active ? {} : { disabled: true })}
          >
            {name}
          </button>
        </Link>
      ) : (
        "-"
      )}
    </>
  );
};

export default Button;
