import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-bold mb-4">404 Not Found</h1>
      <p className="text-lg mb-4">Halaman yang anda tuju tidak tersedia</p>
      <Link to="/home" className="text-blue-500 underline">Kembali ke halaman home</Link>
    </div>
  );
}

export default NotFound;