import Input from "components/Form/Input";
import users from "constants/api/users";
import Member from "layout/Member";
import moment from "moment";
import Button from "parts/Button";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const UpdateProfile = () => {
  const initialState = {
    id: "",
    firstName: "",
    phone: "",
    email: "",
    address: "",
    province: "",
    city: "",
    gender: "",
    date: "",
    month: "",
    year: "",
    avatar: "",
  };

  const [
    {
      id,
      firstName,
      phone,
      email,
      address,
      province,
      city,
      gender,
      date,
      month,
      year,
      avatar,
    },
    setState,
  ] = useState(initialState);

  const [error, setError] = useState([]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const numberToMonth = {
    '1':'Januari',
    '2':'Februari',
    '3':'Maret',
    '4':'April',
    '5':'Mei',
    '6':'Juni',
    '7':'Juli',
    '8':'Agustus',
    '9':'September',
    '10':'Oktober',
    '11':'November',
    '12':'Desember',
  };

  const getDataUser = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var [days, monthStr, years] = "";
    if (user.dob) {
      [days, monthStr, years] = user.dob.split(" ");
    }

    const monthsToNumber = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };

    const monthNumber = monthsToNumber[monthStr];
    setState((state) => ({
      ...state,
      id: user.id,
      firstName: user.firstName,
      phone: user.phone,
      email: user.email,
      address: user.address,
      province: user.province,
      city: user.city,
      gender: user.gender,
      date: days,
      month: monthNumber,
      year: years,
      avatar: user.avatar,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDataUser();
  }, []);

  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const submit = (e) => {
    e.preventDefault();
    const err = [];
    if (firstName === "") {
      if (!err.includes("Nama lengkap belum diisi")) {
        err.push("Nama lengkap belum diisi");
      }
    }

    if (phone === "") {
      if (!err.includes("No HP belum diisi")) {
        err.push("No HP belum diisi");
      }
    }

    if (email === "") {
      if (!err.includes("Email belum diisi")) {
        err.push("Email belum diisi");
      }
    }
    if (!isValidEmail(email)) {
      err.push("Format email tidak sesuai");
    }

    if (address === "") {
      if (!err.includes("Alamat belum diisi")) {
        err.push("Alamat belum diisi");
      }
    }

    if (province === "") {
      if (!err.includes("Provinsi belum diisi")) {
        err.push("Provinsi belum diisi");
      }
    }

    if (city === "") {
      if (!err.includes("Kota/Kabupaten belum diisi")) {
        err.push("Kota/Kabupaten belum diisi");
      }
    }

    if (gender === "") {
      if (!err.includes("Jenis Kelamin belum diisi")) {
        err.push("Jenis Kelamin belum diisi");
      }
    }

    if (month === "" || date === "" || year === "") {
      if (!err.includes("Tanggal Lahir belum diisi")) {
        err.push("Tanggal Lahir belum diisi");
      }
    }

    // if (username === "") {
    //   if (!err.includes("Username belum diisi")) {
    //     err.push("Username belum diisi");
    //   }
    // }

    // if (password === "") {
    //   if (!err.includes("Kata Sandi belum diisi")) {
    //     err.push("Kata Sandi belum diisi");
    //   }
    // }

    // if (password !== confirmPassword) {
    //   if (!err.includes("Kata sandi tidak sesuai")) {
    //     err.push("Kata sandi tidak sesuai");
    //   }
    // }

    if (err.length > 0) {
      setError(err);
      return false;
    }

    users
      .update({
        id: id,
        form: {
          firstName,
          email,
          address,
          province,
          city,
          gender,
          dob: `${year}-${month}-${date}`,
        },
      })
      .then((res) => {
        Swal.fire({
          text: "Berhasil merubah profile.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((result) => {});
        localStorage.setItem("user", JSON.stringify(res.data.result));
        getDataUser();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      users
        .update({
          id: id,
          form: {
            avatar: file,
          },
        })
        .then((res) => {
          console.log(res);
          Swal.fire({
            text: "Berhasil mengunggah foto.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then((result) => {});
          localStorage.setItem("user", JSON.stringify(res.data.result));
          getDataUser();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleImageDelete = (event) => {
    users
      .update({
        id: id,
        form: {
          avatar: "",
        },
      })
      .then((res) => {
        console.log(res);
        Swal.fire({
          text: "Berhasil menghapus foto.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((result) => {});
        localStorage.setItem("user", JSON.stringify(res.data.result));
        getDataUser();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error]);

  return (
    <Member>
      <div className="flex px-10 bg-white h-full pb-20">
        <div className="flex flex-col w-full">
          <div className="flex gap-4 items-center py-10">
            {avatar || selectedImage ? (
              <img
                src={avatar || selectedImage}
                className="rounded-full w-24 h-24"
                alt="profile"
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_BASE_URL}/assets/images/profile.jpeg`}
                className="rounded-full w-24 h-24"
                alt="profile"
              />
            )}
            <div className="flex flex-col">
              <label
                htmlFor="upload-input"
                className="font-light text-primary hover:underline hover:cursor-pointer"
              >
                Unggah Foto
              </label>
              <input
                id="upload-input"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
              <span
                className="font-light text-primary hover:underline hover:cursor-pointer"
                onClick={handleImageDelete}
              >
                Hapus Foto
              </span>
            </div>
          </div>
          {error.length !== 0 && (
            <ul className="flex flex-col text-sm w-full text-primary bg-orange-100 rounded-md px-8">
              {error.map((item, index) => {
                return (
                  <li key={index} className="list-disc">
                    {item}
                  </li>
                );
              })}
            </ul>
          )}
          <Input
            value={firstName}
            name="firstName"
            type="text"
            labelName="Nama lengkap sesuai KTP*"
            placeholder=""
            onChange={onChange}
          />
          <Input
            value={phone}
            name="phone"
            type="text"
            labelName="Nomor HP*"
            placeholder=""
            onChange={onChange}
          />
          <Input
            value={email}
            name="email"
            type="email"
            labelName="Email*"
            placeholder=""
            onChange={onChange}
          />
          <Input
            value={address}
            name="address"
            type="text"
            labelName="Alamat sesuai KTP"
            placeholder=""
            onChange={onChange}
          />
          <Input
            value={province}
            name="province"
            type="text"
            labelName="Provinsi"
            placeholder=""
            onChange={onChange}
          />
          <Input
            value={city}
            name="city"
            type="text"
            labelName="Kota/Kabupaten"
            placeholder=""
            onChange={onChange}
          />
          <Input
            value={gender}
            name="gender"
            type="text"
            labelName="Jenis Kelamin"
            placeholder=""
            onChange={onChange}
          />
          <div className="flex flex-col mb-4 w-full">
            <label
              htmlFor="Tanggal Lahir"
              className={["text-base font-light mb-2"].join(" ")}
            >
              {" "}
              Tanggal Lahir
            </label>
            <div className="flex space-x-4">
              <select
                value={date}
                name="date"
                className="bg-[#EFEFEF] appearance-none rounded-lg focus:outline-none border-none w-full px-6 py-3"
                onChange={onChange}
              >
                {[...Array(31)].map((_, i) => {
                  const displayValue = i + 1;
                  const value =
                    displayValue <= 9 ? `0${displayValue}` : displayValue;

                  return (
                    <option key={value} value={value}>
                      {displayValue}
                    </option>
                  );
                })}
              </select>
              <select
                value={month}
                name="month"
                className="bg-[#EFEFEF] appearance-none rounded-lg focus:outline-none border-none w-full px-6 py-3"
                onChange={onChange}
              >
                {[...Array(12)].map((_, i) => {
                  const displayValue = i + 1;
                  const value =
                    displayValue <= 9 ? `0${displayValue}` : displayValue;

                  return (
                    <option key={value} value={value}>
                      {numberToMonth[displayValue]}
                    </option>
                  );
                })}
              </select>
              <select
                value={year}
                name="year"
                className="bg-[#EFEFEF] appearance-none rounded-lg focus:outline-none border-none w-full px-6 py-3"
                onChange={onChange}
              >
                {}
                {[...Array(moment().format("YYYY") - 1970)].map((_, i) => {
                  return (
                    <option key={i + 1970} value={i + 1970}>
                      {i + 1970}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <Button name="Simpan" type="primary" onClick={submit} />
        </div>
      </div>
    </Member>
  );
};

export default UpdateProfile;
