import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Guest from "layout/Guest";
import MovieCard from "parts/MovieCard";
import Slider from "parts/Slider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import movies from "constants/api/movies";

const Home = () => {
  const [dataMovies, setDataMovies] = useState([]);
  const [dataCS, setDataCS] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    movies
      .all()
      .then((res) => {
        setDataMovies(res.data.result.data);
        // console.log(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
    movies
      .cs()
      .then((res) => {
        setDataCS(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Guest>
      <div className="pb-24">
        <Slider />
        <div className="h-full mb-20 md:mb-0">
          <div className="flex flex-col space-y-4 md:justify-between justify-center m-auto left-0 right-0 max-w-7xl w-full px-8">
            <div className="flex justify-between items-center mt-10">
              <h3 className="text-sm md:text-base font-bold">
                Tayang Sekarang
              </h3>
              {/* <div className="flex items-center">
                <img
                  src={require("../assets/images/Location.png")}
                  alt="location"
                  className="w-8"
                />
                <select
                  name=""
                  id=""
                  className="border-none bg-transparent pl-1 appearance-none focus:outline-none font-extralight pr-10"
                >
                  <option value="77">Cilacap</option>
                </select>
              </div> */}
            </div>
            <div className="grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-9">
              {dataMovies.map((item, index) => {
                return <MovieCard key={index} data={item} href={`/film`} />;
              })}
            </div>
          </div>
          <div className="flex flex-col space-y-4 md:justify-between justify-center m-auto left-0 right-0 max-w-7xl w-full px-8">
            <div className="flex justify-between items-center mt-10">
              <h3 className="text-sm md:text-base font-bold">Segera Tayang</h3>
            </div>
            <div className="grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-9">
              {dataCS.map((item, index) => {
                return (
                  <MovieCard key={index} data={item} href={`/film-segera`} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Guest>
  );
};

export default Home;
