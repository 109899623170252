import theaters from "constants/api/theaters";
import Guest from "layout/Guest";
import Slider from "parts/Slider";
import TheaterCard from "parts/TheaterCard";
import React, { useEffect, useState } from "react";

const Cafetaria = () => {
  const [dataTheaters, setDataTheaters] = useState([]);
  const [activeTheater, setActiveTheater] = useState(null);

  const toggleTheater = (index) => {
    if (activeTheater === index) {
      setActiveTheater(null);
    } else {
      setActiveTheater(index);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    theaters
      .all()
      .then((res) => {
        const storedFavorites =
          JSON.parse(localStorage.getItem("favorites")) || [];
        const arraySetstoredFavorites = new Set(storedFavorites);
        res.data.result.data.sort((a, b) => {
          if (
            arraySetstoredFavorites.has(a.id.toString()) &&
            !arraySetstoredFavorites.has(b.id.toString())
          ) {
            return -1;
          } else if (
            !arraySetstoredFavorites.has(a.id.toString()) &&
            arraySetstoredFavorites.has(b.id.toString())
          ) {
            return 1;
          } else {
            return a.id - b.id;
          }
        });

        setDataTheaters(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Guest>
      <div className="pb-24 bg-white">
        <Slider />
        <div className="flex flex-col space-y-4 md:justify-between justify-center m-auto left-0 right-0 md:w-3/4 w-full px-8">
          <div className="flex justify-between items-center mt-10">
            <h3 className="text-sm md:text-base font-bold">
              Pilih Teater untuk Kafetaria:
            </h3>
          </div>
          <div className="flex flex-col w-full space-y-2 pl-4">
            {dataTheaters?.map?.((item, index) => {
              return (
                <div key={item.id}>
                  <p
                    className="font-light hover:bg-slate-200 p-2 rounded-t-lg cursor-pointer flex items-center justify-between"
                    onClick={() => toggleTheater(index)}
                  >
                    {item.name}
                    <svg
                      className={`w-4 h-4 ml-2 ${
                        activeTheater === index ? "transform rotate-180" : ""
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M19 9l-7 7-7-7" />
                    </svg>
                  </p>
                  {activeTheater === index && (
                    <div className="p-4 bg-slate-200">
                      <TheaterCard
                        key={item.id}
                        name={item.name}
                        addrass={`${item.street}, ${item.city}, ${item.province}, ${item.zipCode}`}
                        type="cafetaria"
                        data={item}
                        thumbnail={item.image}
                        hrefTheater="/kafetaria-menu"
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {/* <div className="flex flex-col space-y-16">
            {dataTheaters.map((item) => (
              <TheaterCard
                key={item.id}
                name={item.name}
                addrass={`${item.street}, ${item.city}, ${item.province}, ${item.zipCode}`}
                type="cafetaria"
                data={item}
                thumbnail={item.image}
                hrefTheater="/kafetaria-menu"
              />
            ))}
          </div> */}
        </div>
      </div>
    </Guest>
  );
};

export default Cafetaria;
