import axios from "configs/axios/v1/index";

export default {
  login: (credentials) => axios.post("/auth/login", credentials),
  all: (options = { params: {} }) => axios.get("/user", options),
  register: (payload) => axios.post("/auth/register", payload),
  refresh: (credentials) =>
    axios.post("/auth/refresh", {
      refreshToken: credentials.refresh_token,
    }),
  update: (data) =>
    axios.put(`/user/${data.id}`, data.form, {
      headers: { "Content-Type": "multipart/form-data" },
    }),

  // details: () => axios.get("/users"),
  // logout: () => axios.post("/users/logout"),
};
