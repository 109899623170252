import Footer from "parts/Footer";
import Navbar from "parts/Navbar";
import React from "react";

const Guest = (props) => {
  return (
    <>
      <Navbar active={window.location.pathname} />
      {props.children}
      <Footer />
    </>
  );
};

export default Guest;
