import React from "react";
import { Carousel } from "react-responsive-carousel";

const Slider = () => {
  return (
    <Carousel
      autoPlay={true}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      className="w-full max-w-7xl m-auto left-0 right-0"
    >
      <div>
        <img alt="imag" src={require("../assets/images/b1.jpeg")} />
      </div>
      <div>
        <img alt="imag" src={require("../assets/images/b2.jpeg")} />
      </div>
      <div>
        <img alt="imag" src={require("../assets/images/b3.jpeg")} />
      </div>
      <div>
        <img alt="imag" src={require("../assets/images/b4.jpeg")} />
      </div>
    </Carousel>
  );
};

export default Slider;
