import cart from "constants/api/cart";
import theaters from "constants/api/theaters";
import orders from "constants/api/orders";
import Member from "layout/Member";
import Button from "parts/Button";
import FnBItem from "parts/FnBItem";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import formatThousand from "helpers/formatThousand";
import Loading from "./Loading";
import moment from "moment";
import CartItem from "parts/CartItem";

const Cart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [theater, setTheater] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const res = await theaters.all();
      const dataTheater = res.data.result.data;

      const dataCart = await Promise.all(
        dataTheater.map(async (item) => {
          try {
            const res = await cart.get({ type: "product", branch: item.id });
            let products = {};
            if (res.data.result.length !== 0) {
              products = {
                ...res.data.result,
              };
            }
            let tickets = {};
            try {
              const resTicket = await cart.get({
                type: "ticket",
                branch: item.id,
              });
              if (resTicket.data.result.length !== 0) {
                const data = resTicket.data.result;
                const groupedDataTicket = data.reduce((acc, curr) => {
                  const {
                    movie,
                    screening,
                    seat,
                    type,
                    purchasePrice,
                    purchasePriceNumber,
                    qty,
                    total,
                    totalNumber,
                  } = curr;
                  const key = screening.id;
                  if (!acc[key]) {
                    acc[key] = {
                      movie,
                      screening,
                      seats: [],
                      type,
                      purchasePrice,
                      purchasePriceNumber,
                      qty: 0,
                      total,
                      totalNumber: 0,
                    };
                  }

                  acc[key].seats.push(seat);
                  acc[key].qty += qty;
                  acc[key].totalNumber += totalNumber;

                  return acc;
                }, {});
                tickets = {
                  ...groupedDataTicket,
                };
              }
            } catch (error) {
              console.log("Error while fetching tickets:", error);
            }

            if (
              Object.keys(products).length === 0 &&
              Object.keys(tickets).length === 0
            ) {
              return null;
            }
            const itemCart = Object.keys(products).map((key) => {
              const product = {
                ...products[key].product,
                checked: true,
                qty: products[key].qty,
                total: products[key].total,
              };
              return {
                ...products[key],
                product: product,
              };
            });
            return {
              ...item,
              carts: { products: itemCart, tickets: tickets },
            };
          } catch (err) {
            console.log("Error while fetching products:", err);
            return {
              ...item,
              carts: null,
            };
          }
        })
      );
      setTheater(dataCart.filter((item) => item !== null));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  const handleOnCheck = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    theater.map((itemTheater) => {
      const dataCart = Object.keys(itemTheater.carts.products).map((key) => {
        if (itemTheater.carts.products[key].product.id !== value) {
          return itemTheater.carts.products[key];
        } else {
          const product = {
            ...itemTheater.carts.products[key].product,
            checked: isChecked,
            qty: isChecked ? 1 : 0,
            total: isChecked ? itemTheater.carts.products[key].total : 0,
          };
          return {
            ...itemTheater.carts.products[key],
            product: product,
          };
        }
      });
      saveCart(dataCart, itemTheater.id);
      return {
        ...itemTheater,
        carts: dataCart,
      };
    });
  };

  const handleMin = (value) => {
    theater.map((itemTheater) => {
      const dataCart = Object.keys(itemTheater.carts.products).map((key) => {
        if (itemTheater.carts.products[key].product.id !== value.id) {
          return itemTheater.carts.products[key];
        } else {
          const product = {
            ...itemTheater.carts.products[key].product,
            checked:
              itemTheater.carts.products[key].product.qty === 1 ? false : true,
            qty: itemTheater.carts.products[key].product.qty - 1,
            total:
              itemTheater.carts.products[key].product.purchasePriceNumber *
              (itemTheater.carts.products[key].product.qty - 1),
          };
          return {
            ...itemTheater.carts.products[key],
            product: product,
          };
        }
      });
      saveCart(dataCart, itemTheater.id);
      return {
        ...itemTheater,
        carts: dataCart,
      };
    });
  };

  const handlePlus = (value) => {
    theater.map((itemTheater) => {
      const dataCart = Object.keys(itemTheater.carts.products).map((key) => {
        if (itemTheater.carts.products[key].product.id !== value.id) {
          return itemTheater.carts.products[key];
        } else {
          const product = {
            ...itemTheater.carts.products[key].product,
            qty: itemTheater.carts.products[key].product.qty + 1,
            total:
              itemTheater.carts.products[key].product.purchasePriceNumber *
              (itemTheater.carts.products[key].product.qty + 1),
          };
          return {
            ...itemTheater.carts.products[key],
            product: product,
          };
        }
      });
      saveCart(dataCart, itemTheater.id);
      return {
        ...itemTheater,
        carts: dataCart,
      };
    });
  };

  const saveCart = (data, idTheater) => {
    const filtered = data.filter((item) => {
      return item.product.checked === true;
    });
    const carts = [];
    filtered.map((item) => {
      carts.push({
        productId: item.product.id,
        qty: item.product.qty,
      });
    });
    cart
      .save({
        param: { type: "product", items: carts },
        branch: idTheater,
      })
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBuyNow = (idTheater) => {
    const filtered = theater.filter((item) => {
      return item.id === idTheater;
    });
    const dataOrders = [];
    filtered[0].carts.products.map((itemCart) => {
      dataOrders.push({
        type: "product",
        productId: itemCart.product.id,
        qty: itemCart.product.qty,
      });
    });
    Object.keys(filtered[0].carts.tickets).map((key) => {
      filtered[0].carts.tickets[key].seats.map((seat) => {
        dataOrders.push({
          type: "ticket",
          screeningId: filtered[0].carts.tickets[key].screening.id,
          seatId: seat.id,
        });
      });
    });
    orders
      .create({ param: { items: dataOrders }, branch: idTheater })
      .then((res) => {
        const order = res.data.result;
        orders
          .pay({
            id: res.data.result.id,
            param: { paymentTypeId: "e3ed72fa-166d-455b-a8e6-ce9ee053187b" },
            branch: idTheater,
          })
          .then((res) => {
            navigate("/pembayaran", {
              state: {
                order: order,
                theater: filtered[0],
                payment: res.data.result,
              },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Member>
      <div className="pt-5 pb-24 bg-white">
        <div className="flex justify-center">
          <div className="flex flex-col w-full md:w-1/2 space-y-8 items-center">
            <h3 className="font-semibold text-2xl">Keranjang Belanja</h3>
            {isLoading ? (
              <Loading />
            ) : theater && theater.length > 0 ? (
              <div className="flex flex-col gap-4 my-4 w-full">
                {theater.map((itemTheater) => {
                  var subTotal = 0;
                  var deposit = 0;
                  return (
                    <div key={itemTheater.id}>
                      <p className="w-full bg- bg-slate-100 p-2 mb-4">
                        {itemTheater.name}
                      </p>
                      {Object.keys(itemTheater.carts.tickets).map((key) => {
                        const seatsName = itemTheater.carts.tickets[key].seats
                          .map((item) => item.name)
                          .join(", ");
                        subTotal =
                          subTotal + itemTheater.carts.tickets[key].totalNumber;
                        return (
                          <CartItem
                            image={
                              itemTheater.carts.tickets[key].movie.picturePath
                            }
                            key={itemTheater.carts.tickets[key].screening.id}
                            name={itemTheater.carts.tickets[key].movie.title}
                            kode="E3R5ZSA"
                            theater={itemTheater.name}
                            time={moment(
                              itemTheater.carts.tickets[key].screening.time,
                              "HH:mm"
                            ).format("HH:mm")}
                            seat={seatsName}
                            // expired="14:49"
                            price={`Rp ${formatThousand(
                              itemTheater.carts.tickets[key].totalNumber
                            )}`}
                          />
                        );
                      })}
                      {Object.keys(itemTheater.carts.products).map((key) => {
                        subTotal =
                          subTotal +
                          itemTheater.carts.products[key].totalNumber;
                        const product = {
                          ...itemTheater.carts.products[key].product,
                          total: itemTheater.carts.products[key].total,
                        };
                        return (
                          <FnBItem
                            data={product}
                            key={product.id}
                            onChange={handleOnCheck}
                            handlePlus={() => handlePlus(product)}
                            handleMin={() => handleMin(product)}
                          />
                        );
                      })}
                      <div className="flex flex-col w-full space-y-8">
                        <div className="flex w-full justify-between px-8">
                          <div></div>
                          <div className="flex flex-col items-end">
                            <p className="font-light">Sub Total:</p>
                            <p className="font-medium">
                              Rp {formatThousand(subTotal)}
                            </p>
                          </div>
                        </div>
                        <div className="flex w-full justify-between px-8">
                          <div></div>
                          {deposit > 0 && (
                            <div className="flex flex-col items-end">
                              <p className="font-light">Sisa Deposit:</p>
                              <p className="font-medium">
                                Rp {formatThousand(deposit)}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="w-full">
                          <div className="border-b-2"></div>
                        </div>
                        <div className="flex w-full justify-between px-8">
                          <div></div>
                          <div className="flex flex-col items-end">
                            <p className="font-light">Total Semua:</p>
                            <p className="font-medium">
                              Rp {formatThousand(subTotal - deposit)}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col space-y-4">
                          <Button
                            name="Bayar Sekarang"
                            type="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleBuyNow(itemTheater.id);
                            }}
                            // href="/pembayaran"
                            // data={total}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="container mx-auto my-10">
                <div className="bg-white rounded-lg shadow-lg p-4">
                  <p className="text-lg text-gray-600">
                    Keranjang Anda kosong.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Member>
  );
};

export default Cart;
