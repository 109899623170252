import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import NotFound from "pages/NotFound";
import Login from "pages/Login";
import Register from "pages/Register";
import Home from "pages/Home";
import Upcoming from "pages/Upcoming";
import Theater from "pages/Theater";
import Cafetaria from "pages/Cafetaria";
import Promotion from "pages/Promotion";
import Investment from "pages/Investment";
import Movie from "pages/Movie";
import ChooseTheater from "pages/ChooseTheater";
import ShowTime from "pages/ShowTime";
import ChooseSeat from "pages/ChooseSeat";
import UpcomingMovie from "pages/UpcomingMovie";
import TheaterMovies from "pages/TheaterMovies";
import CafetariaMenu from "pages/CafetariaMenu";
import Cart from "pages/Cart";
import Payment from "pages/Payment";
import Profile from "pages/Profile";
import PrivateRoute from "utils/PrivateRoute";
import UpdateProfile from "pages/UpdateProfile";
import ChangePassword from "pages/ChangePassword";
import ContactUs from "pages/ContactUs";
import Requirement from "pages/Requirement";
import Transaction from "pages/Transaction";
import CafetariaCart from "pages/CafetariaCart";
import HistoryTransaction from "pages/HistoryTransaction";
import HistoryPayment from "pages/HistoryPayment";
import DetailTransaction from "pages/DetailTransaction";

function App() {
  const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });
  return (
    <>
      <Router history={history}>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Navigate to="/home" replace={true} />}/>
          <Route path="/home" element={<Home />} />
          <Route path="/segera" element={<Upcoming />} />
          <Route path="/teater" element={<Theater />} />
          <Route path="/kafetaria" element={<Cafetaria />} />
          <Route path="/promotion" element={<Promotion />} />
          <Route path="/investment" element={<Investment />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/film" element={<Movie />} />
          <Route path="/pilih-teater" element={<ChooseTheater />} />
          <Route path="/film-segera" element={<UpcomingMovie />} />
          <Route path="/teater-film" element={<TheaterMovies />} />
          <Route path="/kafetaria-menu" element={<CafetariaMenu />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/ubah-profil" element={<UpdateProfile />} />
            <Route path="/ubah-kata-sandi" element={<ChangePassword />} />
            <Route path="/hubungi-kami" element={<ContactUs />} />
            <Route path="/persyaratan-layanan" element={<Requirement />} />
            <Route path="/transaksi" element={<Transaction />} />
            <Route path="/riwayat-transaksi" element={<HistoryTransaction />} />
            <Route path="/jam-tayang" element={<ShowTime />} />
            <Route path="/pilih-kursi" element={<ChooseSeat />} />
            <Route path="/keranjang" element={<Cart />} />
            <Route path="/kafetaria-keranjang" element={<CafetariaCart />} />
            <Route path="/pembayaran" element={<Payment />} />
            <Route path="/riwayat-pembayaran" element={<HistoryPayment/>} />
            <Route path="/rincian-transaksi" element={<DetailTransaction />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
