import axios from "axios";
import users from "constants/api/users";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_APIV1_HOST}`,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  // const token = localStorage.getItem("access_token");
  const refresh_token = localStorage.getItem("refresh_token");
  if (!refresh_token) {
    // If refresh token is null, redirect to the login page
    localStorage.clear();
    window.location.href = "/login";
    return;
  }

  try {
    const res = await users.refresh({ refresh_token: refresh_token });
    localStorage.setItem("access_token", res.data.result.accessToken);
    localStorage.setItem("refresh_token", res.data.result.refreshToken);
    localStorage.setItem("user", JSON.stringify(res.data.result.user));
    return Promise.resolve();
  } catch (error) {
    if (error.response.status === 404) {
      localStorage.clear();
      window.location.href = "/login";
      // console.log(error);
    }
    return Promise.reject(error);
  }
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      console.log(error.response.status, originalRequest._retry);
      originalRequest._retry = true;
      try {
        await refreshAccessToken();
        return instance(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
