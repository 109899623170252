import React from "react";
import Button from "./Button";
import moment from "moment";
const MovieTime = ({ date, price, times = [], data = [] }) => {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-between">
        <p className="font-light">{date}</p>
        <p className="font-light">{price}</p>
      </div>
      <div className="grid grid-cols-4 md:grid-cols-6">
        {times.length > 0 ? (
          times.map((time, i) => (
            <Button
              key={i}
              name={moment(time.time, "HH:mm:ss").format("HH:mm")}
              type="time"
              active={true}
              href="/pilih-kursi"
              data={{ theater: data.theater, time: time }}
            />
          ))
        ) : (
          <p className="font-light">Tidak ada jadwal</p>
        )}
      </div>
    </div>
  );
};

export default MovieTime;
