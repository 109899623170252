import React, { useState, useEffect } from "react";

const Countdown = ({ startTime }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    const targetTime = new Date(now);

    targetTime.setHours(startTime.hours);
    targetTime.setMinutes(startTime.minutes);
    targetTime.setSeconds(0);

    if (now > targetTime) {
      targetTime.setDate(targetTime.getDate() + 1);
    }

    const diff = targetTime.getTime() - now.getTime();
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    return { hours, minutes, seconds };
  }

  useEffect(() => {
    if (timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
      window.location.reload();
      alert("Waktu pilih habis habis, silahkan ulang kembali.");
    }
  }, [timeLeft]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  return (
    <p className="font-light text-sm">
      {" "}
      Tersisa:{" "}
      <span className="underline">
          <span>{formatTime(timeLeft.hours)}</span>:
          <span>{formatTime(timeLeft.minutes)}</span>:
          <span>{formatTime(timeLeft.seconds)}</span>
      </span>
    </p>
  );
};

export default Countdown;
