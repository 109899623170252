import cart from "constants/api/cart";
import fnb from "constants/api/fnb";
import orders from "constants/api/orders";
import screening from "constants/api/screening";
import formatThousand from "helpers/formatThousand";
import Guest from "layout/Guest";
import moment from "moment";
import Button from "parts/Button";
import CartItem from "parts/CartItem";
import FnBItem from "parts/FnBItem";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NotFound from "./NotFound";

const CafetariaCart = () => {
  const location = useLocation();
  const data = location.state;
  const idTheater = data?.theater.id;
  const [deposit, setDeposit] = React.useState(10000);
  const [subTotal, setSubTotal] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [dataFnB, setDataFnB] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    window.scrollTo(0, 0);
    if (data) {
      const filtered = data.dataFnB.filter((item) => {
        return item.checked === true;
      });
      setDataFnB(filtered);
    }
  }, []);

  useEffect(() => {
    let sum = 0;
    dataFnB.map((item) => {
      // console.log(item.total);
      sum += item.total;
    });
    setSubTotal(sum);
    setTotal(sum - deposit);
  }, [dataFnB]);

  const handleOnCheck = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    const data = dataFnB.map((item) => {
      if (item.id !== value) {
        return item;
      } else {
        return {
          ...item,
          checked: isChecked,
          qty: isChecked ? 1 : 0,
          total: isChecked ? item.purchasePriceNumber : 0,
        };
      }
    });
    setDataFnB(data);
  };

  const handleMin = (value) => {
    const data = dataFnB.map((item) => {
      if (item.id !== value.id) {
        return item;
      } else {
        return {
          ...item,
          checked: item.qty === 1 ? false : true,
          qty: item.qty - 1,
          total: item.purchasePriceNumber * (item.qty - 1),
        };
      }
    });
    setDataFnB(data);
  };

  const handlePlus = (value) => {
    const data = dataFnB.map((item) => {
      if (item.id !== value.id) {
        return item;
      } else {
        return {
          ...item,
          qty: item.qty + 1,
          total: item.purchasePriceNumber * (item.qty + 1),
        };
      }
    });
    setDataFnB(data);
  };

  const handleBuyNow = (e) => {
    e.preventDefault();
    const products = [];
    const carts = [];
    dataFnB.map((item) => {
      products.push({
        type: "product",
        productId: item.id,
        qty: item.qty,
      });
      carts.push({
        productId: item.id,
        qty: item.qty,
      });
      cart
        .save({
          param: { type: "product", items: carts },
          branch: idTheater,
        })
        .then((res) => {
          console.log('sukses');
        })
        .catch((err) => {
          console.log(err);
        });
      // orders
      //   .ticket({ param: { items: products }, branch: idTheater })
      //   .then((res) => {
      //     console.log(res);
      //     // navigate("/keranjang", {
      //     //   state: {
      //     //     data: res.data,
      //     //   },
      //     // });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    });
  };
  return (
    <Guest>
      {data ? (
        <div className="pt-10 pb-20 bg-white">
          <div className="flex justify-center">
            <div className="flex flex-col w-full md:w-1/2 space-y-8 items-center">
              <h3 className="font-semibold text-2xl">Keranjang Belanja</h3>
              <div className="flex flex-col gap-4 my-4 w-full">
                {dataFnB.map((item, index) => {
                  return (
                    <FnBItem
                      data={item}
                      key={index}
                      onChange={handleOnCheck}
                      handlePlus={() => handlePlus(item)}
                      handleMin={() => handleMin(item)}
                    />
                  );
                })}
              </div>
              <div className="flex w-full justify-between px-8">
                <div></div>
                <div className="flex flex-col items-end">
                  <p className="font-light">Sub Total:</p>
                  <p className="font-medium">Rp {formatThousand(subTotal)}</p>
                </div>
              </div>
              <div className="flex w-full justify-between px-8">
                <div></div>
                <div className="flex flex-col items-end">
                  <p className="font-light">Sisa Deposit:</p>
                  <p className="font-medium">Rp {formatThousand(deposit)}</p>
                </div>
              </div>
              <div className="w-full">
                <div className="border-b-2"></div>
              </div>
              <div className="flex w-full justify-between px-8">
                <div></div>
                <div className="flex flex-col items-end">
                  <p className="font-light">Total Semua:</p>
                  <p className="font-medium">Rp {formatThousand(total)}</p>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                {/* <Button
                name="Tambahkan Voucher"
                type="light"
                href="/pembayaran"
              /> */}
                <Button
                  name="Bayar Sekarang"
                  type="primary"
                  onClick={handleBuyNow}
                  replace
                  // href="/pembayaran"
                  // data={total}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </Guest>
  );
};

export default CafetariaCart;
