import Guest from "layout/Guest";
import Button from "parts/Button";
import TheaterCard from "parts/TheaterCard";
import React, { useEffect, useState } from "react";
import fnb from "constants/api/fnb";
import FnBItem from "parts/FnBItem";
import { useLocation, useNavigate } from "react-router-dom";
import NotFound from "./NotFound";
import cart from "constants/api/cart";
import Swal from "sweetalert2";

const CafetariaMenu = () => {
  const [dataFnB, setDataFnB] = React.useState([]);
  const [FnB, setFnB] = React.useState([]);
  const [category, setCategory] = React.useState("FOOD");
  const [status, setStatus] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const theater = location.state;
  const idTheater = theater?.id;
  useEffect(() => {
    if (status) {
      window.scrollTo(0, 0);
      if (theater) {
        getFnB();
      }
    }
    categoryFnB(category);
  }, [dataFnB]);

  const categoryFnB = (value) => {
    setCategory(value);
    // console.log('dsf',dataFnB);
    const filtered = dataFnB.filter((item) => {
      return item.category.code === value;
    });
    setFnB(filtered);
    // if (value === "food" || value === "drink") {
    // } else {
    //   setFnB(dataFnB);
    // }
  };

  const getFnB = () => {
    fnb
      .all({ branch: idTheater })
      .then((res) => {
        // const data = res.data.result.data;
        // console.log(data);
        const data = res.data.result.data.map((item) => {
          return {
            ...item,
            checked: false,
            qty: 0,
            total: 0,
          };
        });
        setDataFnB(data);
        setStatus(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnCheck = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    
    const data = dataFnB.map((item) => {
      // console.log(item);
      if (item.id !== value) {
        return item;
      } else {
        return {
          ...item,
          checked: isChecked,
          qty: isChecked ? 1 : 0,
          total: isChecked ? item.purchasePriceNumber : 0,
        };
      }
    });
    setDataFnB(data);
  };

  const handleMin = (value) => {
    const data = dataFnB.map((item) => {
      if (item.id !== value.id) {
        return item;
      } else {
        return {
          ...item,
          checked: item.qty === 1 ? false : true,
          qty: item.qty - 1,
          total: item.purchasePriceNumber * (item.qty - 1),
        };
      }
    });
    setDataFnB(data);
  };

  const handlePlus = (value) => {
    const data = dataFnB.map((item) => {
      if (item.id !== value.id) {
        return item;
      } else {
        return {
          ...item,
          qty: item.qty + 1,
          total: item.purchasePriceNumber * (item.qty + 1),
        };
      }
    });
    setDataFnB(data);
  };

  const handleAddCart = () => {
    const filtered = dataFnB.filter((item) => {
      return item.checked === true;
    });
    const carts = [];
    filtered.map((item) => {
      carts.push({
        productId: item.id,
        qty: item.qty,
      });
    });

    cart
      .save({
        param: { type: "product", items: carts },
        branch: idTheater,
      })
      .then((res) => {
        Swal.fire({
          text: "Berhasil di tambahkan ke keranjang.",
          confirmButtonColor: "#C61717",
          confirmButtonText: "Lihat Keranjang",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/keranjang", { replace: true });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Guest>
      {theater ? (
        <div className="bg-white h-full pb-20">
          {/* <div className="flex flex-col space-y-8 w-full"> */}
          <div className="flex flex-col md:justify-between justify-center m-auto left-0 right-0 max-w-7xl w-full ">
            <div className="flex flex-col items-center bg-[#F5F5F5] py-10">
              <div className="w-full md:w-2/3 px-4">
                <TheaterCard
                  name={theater.name}
                  addrass={`${theater.street}, ${theater.city}, ${theater.province}, ${theater.zipCode}`}
                  thumbnail={theater.image}
                  data={theater}
                />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <h3 className="font-semibold text-2xl my-10">Pilih Menu</h3>

              <div className="flex flex-col md:flex-row w-full md:w-2/3">
                <ul className="grid grid-flow-col md:grid-flow-row text-center min-w-[125px] h-fit bg-[#EFEFEF] md:m-4">
                  <li
                    className={`p-2 hover:bg-secondary w-full font-light ${
                      category === "FOOD" ? "bg-secondary" : ""
                    }`}
                  >
                    <button onClick={() => categoryFnB("FOOD")}>Food</button>
                  </li>
                  <li
                    className={`p-2 hover:bg-secondary w-full font-light ${
                      category === "paket" ? "bg-secondary" : ""
                    }`}
                  >
                    <button onClick={() => categoryFnB("paket")}>Paket</button>
                  </li>
                  <li
                    className={`p-2 hover:bg-secondary w-full font-light ${
                      category === "alacarte" ? "bg-secondary" : ""
                    }`}
                  >
                    <button onClick={() => categoryFnB("alacarte")}>
                      Ala Carte
                    </button>
                  </li>
                  <li
                    className={`p-2 hover:bg-secondary w-full font-light ${
                      category === "BVRG" ? "bg-secondary" : ""
                    }`}
                  >
                    <button onClick={() => categoryFnB("BVRG")}>Minuman</button>
                  </li>
                </ul>
                <div className="flex flex-col gap-4 my-4 w-full">
                  {FnB.length ? (
                    FnB.map((item, index) => {
                      return (
                        <FnBItem
                          data={item}
                          key={index}
                          onChange={handleOnCheck}
                          handlePlus={() => handlePlus(item)}
                          handleMin={() => handleMin(item)}
                        />
                      );
                    })
                  ) : (
                    <div className="flex justify-center items-center bg-secondary h-full">
                      Belum tersedia
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="w-full ">
            </div> */}
            </div>

            {/* <div className="flex w-full justify-center py-10">
            <div className="flex flex-col md:w-2/3 space-y-4 items-center">
              <h3 className="font-semibold text-2xl">Pilih Menu</h3>
              <div className="grid grid-cols-6 w-full space-x-20">
                <ul className="flex flex-col justify-center items-center bg-[#D9D9D9]">
                  <li
                    className={`p-2 hover:bg-secondary w-full font-light ${
                      category === "promo" ? "bg-secondary" : ""
                    }`}
                  >
                    <button onClick={() => categoryFnB("promo")}>Promo</button>
                  </li>
                  <li
                    className={`p-2 hover:bg-secondary w-full font-light ${
                      category === "paket" ? "bg-secondary" : ""
                    }`}
                  >
                    <button onClick={() => categoryFnB("paket")}>Paket</button>
                  </li>
                  <li
                    className={`p-2 hover:bg-secondary w-full font-light ${
                      category === "alacarte" ? "bg-secondary" : ""
                    }`}
                  >
                    <button onClick={() => categoryFnB("alacarte")}>
                      Ala Carte
                    </button>
                  </li>
                  <li
                    className={`p-2 hover:bg-secondary w-full font-light ${
                      category === "drink" ? "bg-secondary" : ""
                    }`}
                  >
                    <button onClick={() => categoryFnB("drink")}>
                      Minuman
                    </button>
                  </li>
                </ul>
                {FnB.map((item, index) => {
                  return (
                    <FnBItem
                      data={item}
                      key={index}
                      onChange={handleOnCheck}
                      handlePlus={() => handlePlus(item)}
                      handleMin={() => handleMin(item)}
                    />
                  );
                })}
              </div>
              <div className="flex justify-end w-full md:w-2/3">
                <Button name="Tambahkan ke Keranjang" type="primary" />
              </div>
            </div>
          </div> */}
            <div className="flex justify-end w-full md:w-2/3">
              <Button
                name="Tambahkan ke Keranjang"
                type="primary"
                // href="/kafetaria-keranjang"
                // data={{ dataFnB: dataFnB, theater: theater }}
                onClick={() => handleAddCart()}
              />
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </Guest>
  );
};

export default CafetariaMenu;
